import React from 'react';
import styled from 'react-emotion';
import theme from '@jetshop/ui/utils/theme';

const PromotionWrapper = styled('div')`
  display: block;
  position: absolute;
  background: ${theme('colors.discountedPrice')};
  width: 50px;
  height: 50px;
  border-radius: 50px;
  color: #fff;
  line-height: 50px;
  text-align: center;
  font-size: 18px;
  top: 10px;
  right: 10px;
`;

function getPercentageChange(oldNumber, newNumber) {
  let decreaseValue = oldNumber - newNumber;
  let percentage = (decreaseValue / oldNumber) * 100;

  return '-' + Math.round(percentage) + '%';
}

const PricePromotion = ({ price, previousPrice, children, ...rest }) => (
  <PromotionWrapper {...rest}>
    {getPercentageChange(previousPrice, price)}{' '}
    {React.Children.map(children, item => ({ item }))}
  </PromotionWrapper>
);

export default PricePromotion;
