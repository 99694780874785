import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';

const TabHeader = styled('li')`
  display: inline-block;
  list-style: none;
  padding: 1rem;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 1.2rem;
  border: 1px solid white;
  position: relative;

  &.tab-list-active {
    background-color: white;
    border: 1px solid #efefef;
    border-bottom-color: white;
    font-weight: bold;
    &:after {
      content: '';
      background: white;
      top: 100%;
      left: 0;
      right: 0;
      height: 2px;
      width: 100%;
      display: block;
      position: absolute;
    }
  }
`;

class Tab extends Component {
  static propTypes = {
    activeTab: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  onClick = () => {
    const { label, onClick } = this.props;
    onClick(label);
  }

  render() {
    const {
      onClick,
      props: {
        activeTab,
        label,
      },
    } = this;

    let className = 'tab-list-item';

    if (activeTab === label) {
      className += ' tab-list-active';
    }

    return (
      <TabHeader
        className={className}
        onClick={onClick}
      >
        {label}
      </TabHeader>
    );
  }
}

export default Tab;