import CategoryLink from '@jetshop/ui/CategoryLink';
import UIMenuContainer from '@jetshop/ui/Menu/MenuContainer';
import React from 'react';
import styled from 'react-emotion';
import theme from '@jetshop/ui/utils/theme';

const Wrapper = styled('div')`
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: auto;
    max-width: 80em;
    padding: 0;

    [data-channel='Skoterdelen'] & {
      justify-content: flex-start;
      a {
        &:hover {
          color: ${theme('colors.red')};
        }
      }
    }

    li {
      list-style: none;
      font-size: 16px;
      [data-channel='Eyewearstore'] & {
        margin: 0 30px;
      }

      a {
        text-transform: uppercase;
        transition: color 0.2s;
        &:hover {
          text-decoration: none;
          color: ${theme('colors.eyeWearYellow')};
        }
      }

      > * {
        padding: 10px 4px 16px 16px;
        [data-channel='Eyewearstore'] & {
          padding: 10px 0;
          font-weight: bold;
        }
        @media (max-width: 1220px) {
          padding: 16px 12px;
        }
      }
    }
  }
`;

const MainMenuItem = styled('li')`
  a {
    padding: 20px 10px;
    font-size: 14px;
    letter-spacing: 0.3px;
    display: inline-block;
    text-decoration: none;
    color: black;

    [data-channel='Skoterdelen'] & {
      color: white;
    }

    &:hover {
      color: white;
    }
    &.active {
      font-weight: bold;
    }
  }
`;

const MenuContainer = ({ queryData }) => {
  return (
    <UIMenuContainer>
      {(props) => (
        // When mousing outside the menu, close it
        <Wrapper>
          <ul>
            {queryData && queryData.categories
              ? queryData.categories.map((cat) => (
                  <MainMenuItem key={cat.id}>
                    <CategoryLink category={cat}>{cat.name}</CategoryLink>
                  </MainMenuItem>
                ))
              : null}
          </ul>
        </Wrapper>
      )}
    </UIMenuContainer>
  );
};

export default MenuContainer;
