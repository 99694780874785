import React from 'react';
import boot from '@jetshop/core/boot/client';
import Shop from './components/Shop';
import config from './shop.config';
import Theme from './components/Theme';

boot(
  <Theme>
    <Shop />
  </Theme>,
  config
);
