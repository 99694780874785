import React, { Component } from 'react';
import styled from 'react-emotion';

import theme from '@jetshop/ui/utils/theme';

import LoadingPage from '../LoadingPage';

export const ReviewWrapper = styled('div')`
  width: 100%;
  margin: auto;
  text-align: center;
  overflow-x: hidden;
  ${theme('below.lg')} {
    width: 90vw;

    .yotpo .promoted-products-box .promoted-products {
      overflow: hidden;
      overflow-x: scroll;
    }
    .yotpo .promoted-products-box .yotpo-promoted-products {
      width: 100%;
      margin: auto !important;
    }
    .yotpo .promoted-products-box .promoted-products li {
      width: 45%;
    }
  }
`;

class ProductReviews extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false
    };
  }
  componentDidMount() {
    if (typeof window !== 'undefined') {
      setTimeout(() => {
        if (document.getElementsByClassName('yotpo-reviews').length > 0) {
          this.init();
        }
      }, 200);
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.yotpo = undefined;
      window.removeEventListener('load', this.init);
    }
  }

  init = () => {
    var e = document.createElement('script');
    e.type = 'text/javascript';
    e.async = true;
    var apiKey = 'XSUDwJM4iTWs4ycz0OmoljxHroJKK9ea8txNXMUz';
    e.src = 'https://staticw2.yotpo.com/' + apiKey + '/widget.js';
    e.defer = true;
    e.id = 'yotpoScript';
    var t = document.getElementsByTagName('script')[0];
    t.parentNode.insertBefore(e, t);
    this.setState({ loaded: true });
    if (typeof window !== 'undefined') {
      if (
        window.yotpo !== undefined &&
        yotpo.widgets &&
        yotpo.widgets.length > 0
      ) {
        var api = new Yotpo.API(yotpo);
        api.refreshWidgets();
      }
    }
  };
  render() {
    const { product } = this.props;
    if (typeof window !== 'undefined') {
      if (
        window.yotpo !== undefined &&
        yotpo.widgets &&
        yotpo.widgets.length > 0
      ) {
        var api = new Yotpo.API(yotpo);
        api.refreshWidgets();
      }
    }

    return (
      <React.Fragment>
        <div className="yotpo-reviews" />
        <ReviewWrapper>
          <div
            className="yotpo yotpo-main-widget"
            data-product-id={product.articleNumber}
            data-price={product.price.incVat}
            data-currency="SEK"
            data-name={product.name}
            data-url={product.primaryRoute.path}
            data-image-url={product.images.url}
            data-description={product.description}
          />
        </ReviewWrapper>
      </React.Fragment>
    );
  }
}

export default ProductReviews;
