import UIButton from '@jetshop/ui/Button';
import styled, { css } from 'react-emotion';
import { Link } from 'react-router-dom';

const SecondaryStyle = () => css`
  background-color: white;
  border-color: black;
  color: black;
`;

const TrendButtonStyle = ({ secondary, theme }) => css`
  background-color: #2f80ed;
  border-color: #2f80ed;
  color: white;
  ${theme.above.md} {
    max-width: 100%;
  }

  ${secondary && SecondaryStyle()};
`;

const Button = styled(UIButton)`
  ${TrendButtonStyle};
`;

export const TrendLink = styled(Link)`
  ${TrendButtonStyle};
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 54px;
`;

export default Button;
