import CartProvider from '@jetshop/core/components/Query/CartProvider';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import theme from '@jetshop/ui/utils/theme';
import get from 'lodash.get';
import React, { Fragment } from 'react';
import styled from 'react-emotion';

import cartQuery from './CartQuery.gql';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faShoppingCart } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VatContext } from '../Price/VatContext';

library.add(faShoppingCart);

const Button = styled('button')`
  position: relative;
  padding: 0;
  background: transparent;
  color: inherit;
  border: 0;
  outline: none;
  margin-left: 20px;
  transition: all, 0.2s;
  ${theme('below.md')} {
    margin: 0;
    color: white;
    padding: 10px;
  }
  :hover {
    color: ${theme('colors.red')};
  }
  svg {
    margin-right: 10px;
    font-size: 2rem;
    width: 2rem;
    height: 2rem;
    color: black;
    [data-channel='Skoterdelen'] & {
      color: white;
    }

    ${theme('below.md')} {
      margin: 0;
      font-size: 1.4rem;
      width: 1.4rem;
      height: 1.4rem;
    }
  }
  div {
    display: inline-block;
    span {
      display: block;
      margin: 0;
      text-align: left;

      &:first-child {
        font-size: 18px;
      }
      &:last-child {
        font-size: 11px;
      }
      [data-channel='Eyewearstore'] & {
        color: black;
      }
    }
  }
`;

const CartBadge = styled('span')`
  color: #fff;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 20px;
  position: absolute;
  top: -5px;
  left: 23px;
  font-size: 12px;
  background: ${theme('colors.green')};
  [data-channel='Skoterdelen'] & {
    background: ${theme('colors.red')};
  }
  ${theme('below.md')} {
    width: 15px;
    height: 15px;
    line-height: 15px;
    top: -5px;
    right: -10px;
    left: auto;
    font-size: 12px;
  }
`;

const CartButton = () => (
  <VatContext.Consumer>
    {({ incVat }) => (
      <CartProvider query={cartQuery}>
        {(result) => {
          // Set items in cart to the API result.
          // If the result is undefined, fall back to 0
          //const itemsInCart = get(result, 'data.cart.totalQuantity', 0);
          let itemsTotal = get(
            result,
            incVat
              ? 'data.cart.productTotal.incVat'
              : 'data.cart.productTotal.exVat',
            0
          );
          itemsTotal = new Intl.NumberFormat('sv-SE', {
            style: 'currency',
            currency: 'SEK',
            minimumFractionDigits: 0,
          }).format(itemsTotal);
          const itemsCount = get(result, 'data.cart.totalQuantity', 0);

          return (
            <DrawerTrigger preventOverflow={true} id="cart-drawer">
              {(drawer) => (
                <Button
                  onClick={
                    drawer.isOpen ? drawer.hideTarget : drawer.showTarget
                  }
                >
                  <Fragment>
                    <FontAwesomeIcon icon={['fal', 'shopping-cart']} />
                    {itemsCount > 0 && <CartBadge>{itemsCount}</CartBadge>}
                  </Fragment>
                </Button>
              )}
            </DrawerTrigger>
          );
        }}
      </CartProvider>
    )}
  </VatContext.Consumer>
);

export default CartButton;
