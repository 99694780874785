import { useEffect } from 'react';

let passiveSupported = false;
try {
  var opts = Object.defineProperty({}, 'passive', {
    get() {
      passiveSupported = true;
      return true;
    }
  });
  window.addEventListener('testPassive', null, opts);
  window.removeEventListener('testPassive', null, opts);
} catch (e) { }

const toggleScrolled = () => {
  if (window.scrollY < 92) {
    document.body.setAttribute('data-sticky-header', false);
  } else {
    document.body.setAttribute('data-sticky-header', true);
  }
};

function useScroll() {
  useEffect(() => {
    window.addEventListener(
      'scroll',
      toggleScrolled,
      passiveSupported ? { passive: true } : false
    );

    return () =>
      window.removeEventListener(
        'scroll',
        toggleScrolled,
        passiveSupported ? { passive: true } : false
      );
  }, []);
};

export { useScroll }
