import { library } from '@fortawesome/fontawesome-svg-core';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import { useDynamicPrice } from '@jetshop/core/hooks/useDynamicPrice';
import { useProductVariants } from '@jetshop/core/hooks/useProductVariants';
import { useAddwish } from '@jetshop/flight-addwish';
import { default as Breadcrumbs } from '@jetshop/ui/Breadcrumbs';
import getParentOrCanonicalRoute from '@jetshop/ui/Breadcrumbs/getParentsOrCanonicalRoute';
import { Above, Below } from '@jetshop/ui/Breakpoints';
import theme from '@jetshop/ui/utils/theme';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-apollo';
import styled from 'react-emotion';
import { Link } from 'react-router-dom';
import { useChannel } from '../../hooks/useChannel';
import GridProduct from '../CategoryPage/GridProduct';
import ProductGrid from '../CategoryPage/ProductGrid';
import MaxWidth from '../Layout/MaxWidth';
import { Price } from '../Price';
import PricePromotion from '../Price/PricePromotion';
import { useVat } from '../Price/VatContext';
import { AddWishProducts } from '../ui/AddWishProducts';
import ProductRatings from '../Yotpo/ProductRating';
import ProductReviews from '../Yotpo/ProductReviews';
import Questions from '../Yotpo/Questions';
import YotpoProductAlbum from '../Yotpo/YotpoProductAlbum';
import AddToCartForm from './AddToCart/AddToCartForm';
import ImageContainer from './Images/ImageContainer';
import { ProductSection } from './ProductInfo';
import ProductInfoAccordion from './ProductInfoAccordion.js';
import ProductPageLoadingState from './ProductPageLoadingState';
import ProductTabs from './ProductTabs.js';
import ProductToolbar from './ProductToolbar';
import StockStatusCategoryQuery from './StockStatusCategoryQuery.gql';
import { useStocklevelCalc } from './useStocklevelCalc';
import { VariantSelector } from './VariantSelector';
import { Redirect } from 'react-router';


const yearRegex = /\b(19|20)\d{2}\b/;

library.add(faStar, faCheck);

const Container = styled(MaxWidth)`
  flex-wrap: wrap;
  flex-direction: row;
  ${theme('below.md')} {
    padding: 0;
    > section {
      padding: 0 10px;
    }
  }

  #product-tabs {
    width: 100%;
  }
`;

const ProductImageSection = styled(ProductSection)`
  width: 60%;
  position: relative;
  padding-bottom: 2rem;
  > div {
    left: 30px;
    right: auto;
    z-index: 2;
    ${theme('below.md')} {
      flex-direction: column;
    }
  }
  ${theme('below.md')} {
    width: 100%;
    padding: 0 !important;
  }
`;
const ProductDetailsSection = styled(ProductSection)`
  width: 40%;
  ${theme('below.md')} {
    width: 100%;
  }
`;

const Heading = styled('h1')`
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 5px;
  text-align: center;
  max-width: 400px;
  ${theme('below.md')} {
    text-align: left;
    font-size: 1.2rem;
  }
`;

const ArticleNumber = styled('p')`
  font-size: 13px;
  color: #9a9a9a;
  margin-bottom: 20px;
  text-align: center;
  ${theme('below.md')} {
    text-align: left;
    margin-bottom: 10px;
  }
`;

const ShortDescription = styled('article')`
  line-height: 1.75;
  font-size: 0.875rem;
  margin-bottom: 1rem;

  p {
    margin-bottom: 1rem;
  }
`;

const ProductContainer = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 1rem;
  @media (max-width: 1214px) {
    padding-top: 5rem;
  }
  ${theme('below.md')} {
    padding-top: 1rem;
  }
`;

const ProductDetailsWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;

  .price-wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    span {
      display: block;
      margin-bottom: 1rem;
    }

    span.suffix {
      margin: 0;
      font-size: 2.5rem;
      font-weight: normal;
      ${theme('below.md')} {
        font-size: 2rem;
      }

      &.sale {
        color: #cd3132;
        font-weight: 900;
      }
    }
  }

  ${Price.New} {
    color: ${theme('colors.red')};
    font-size: 2.5rem;
    font-weight: 900;
    ${theme('below.md')} {
      font-size: 2rem;
    }
  }

  ${Price.Old} {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 10px;
    ${theme('below.md')} {
      font-size: 1.2rem;
    }
  }

  ${Price.Wrapper} {
    margin-bottom: 2rem;
    text-align: center;
  }
  ${Price.Normal} {
    font-size: 2.5rem;
    ${theme('below.md')} {
      font-size: 2rem;
    }
  }
`;

const UspWrapper = styled('div')`
  display: flex;
  justify-content: space-evenly;
  padding: 1rem 0;
  ${theme('below.md')} {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const IconTextWrapper = styled('div')`
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 18px;
    text-transform: uppercase;
    font-size: 13px;
    color: #000;
    text-decoration: none;
    padding: 0 4px;
  }
`;
const UspIcon = styled(FontAwesomeIcon)`
  font-size: 1.1rem;
  margin-right: 5px;
`;

export const StockStatusWrapper = styled('div')`
  margin-top: 1rem;
  cursor: pointer;
  position: relative;
  width: 100%;

  span {
    display: block;
    text-align: center;
    font-size: 1.2rem;
    color: ${theme('colors.green')};
    line-height: 1.2;

    &.notBuyable {
      color: ${theme('colors.red')};
    }
  }
  &:hover {
    > div {
      opacity: 1;
      visibility: visible;
    }
  }
`;

export const HiddenContent = styled('div')`
  visibility: hidden;
  opacity: 0;
  transition: all, 0.2s;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  border: 1px solid #ddd;
  width: 72.5%;
  margin: auto;
  padding: 10px 20px;
  text-align: center;
`;

const ProductPaymentWrapper = styled('div')`
  margin-top: 1rem;
`;

const ModelTable = styled('table')`
  width: 100%;

  tr {
    td:first-child {
      width: 50%;
    }
  }

  tr:nth-child(even) {
    background: #f8f8f8;
  }

  th {
    font-weight: 900;
    text-align: left;
    padding-top: 1.5rem !important;
  }
  td,
  th {
    padding: 5px;
  }
  td {
    text-align: left;
  }
`;

const StyledBreadcrumbs = styled(Breadcrumbs)`
  text-transform: uppercase;
  a,
  li {
    font-size: 14px;
    ${theme('below.md')} {
      white-space: pre-wrap;
    }
  }
  li:first-child {
    display: none;
  }
`;

const ProductInfo = styled('div')`
  ${theme('below.md')} {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`;
const Left = styled('div')`
  ${theme('below.md')} {
    text-align: left;
    max-width: 230px;
  }
`;
const Right = styled('div')`
  ${theme('below.md')} {
    text-align: right;
  }
`;

const AddWishTitle = styled('h3')`
  font-weight: bold;
  font-size: 1.5rem;
  text-transform: uppercase;
  text-align: center;
  max-width: 400px;
  display: block;
  text-align: center;
  width: 100%;
  margin: 1rem auto 1rem;
`;

const AddWishWrapper = styled('div')`
  display: block;
  margin-bottom: 2rem;
  width: 100%;
  .addwish-grid {
    width: 100%;
    flex-wrap: nowrap;
    justify-content: center;
    > div {
      margin: 0.2rem;
      width: 25%;
    }
  }
  ${theme('below.md')} {
    margin: 0;
    .addwish-grid {
      flex-wrap: wrap;
      > div {
        width: 45%;
        margin-bottom: 0.5rem;
      }
    }
  }
`;

export const StockStatus = ({ stockStatus }) => {
  //This is a hybrid using the old stockCalc but fetches new status text from admin,
  const result = useQuery(StockStatusCategoryQuery, {
    variables: {
      id: 12887,
    },
  });
  const removeTags = (textString) => {
    if (!textString) {
      return '';
    }
    return textString.replace(/(<([^>]+)>)/gi, '');
  };

  if (!result?.data?.category?.content) {
    return null;
  }

  let contentObj;
  try {
    const contentString = removeTags(result?.data?.category?.content);
    contentObj = JSON.parse(contentString);
  } catch (error) {
    console.warn('********** could not parse stockstatus json');
  }
  const dictKey = String(stockStatus?.key);

  if (!dictKey) return null;
  return (
    <StockStatusWrapper>
      <span className={stockStatus?.className}>{contentObj?.[dictKey]}</span>
      {/* <HiddenContent>{contentObj?.[String(dictKey)]}</HiddenContent> */}
    </StockStatusWrapper>
  );
};

const videoLink = (customFields) =>
  customFields?.find((c) => c.title === 'Youtube')?.stringValue ?? false;

const Product = ({ result: { data, loading }, product, cacheHit }) => {
  const [activeTab, setActiveTab] = useState(false);
  const [clickIndex, setClickIndex] = useState(0);
  const { selectedChannel } = useContext(ChannelContext);
  const images = product?.images ?? [];
  const parents = getParentOrCanonicalRoute(data?.route?.parents, product);
  const video = videoLink(product?.customFields ?? []);
  const isUniversal = product?.customFields?.find(
    (field) => field.key === 'universal-del'
  );

  const { isWeAhl, url: baseUrl } = useChannel();

  function handleClick(e, index) {
    setActiveTab('Recensioner');
    setClickIndex(clickIndex + 1);
    document
      .getElementById('product-tabs')
      .scrollIntoView({ block: 'start', behavior: 'smooth' });
  }

  const variantHandler = useProductVariants(
    product.articleNumber ? product : undefined
  );

  const {
    selectedVariant: selectedVariation,
    getMissingOptions,
  } = variantHandler;

  const price = useDynamicPrice(product, selectedVariation);

  const [incVat] = useVat();

  const { productStockStatus } = useStocklevelCalc({
    product: selectedVariation || product,
  });

  const tabContent = useProductTabContent(product?.description);

  // const ADDWISH_BOXES = {
  //   k5b05534de051812b75f52124: {
  //     url:
  //       product.primaryRoute &&
  //       selectedChannel.url + product?.primaryRoute?.path,
  //   },
  //   k5b0552c8e051812b75f51f77: {
  //     url:
  //       product.primaryRoute &&
  //       selectedChannel.url + product?.primaryRoute?.path,
  //   },
  // };

  // const { addWishResponseBoxItems } = useAddwish({
  //   boxes: ADDWISH_BOXES,
  //   pageLoaded: true,
  // });

  const { addWishResponseBoxItems } = useAddwish({
    boxes: {
      ['k5b05534de051812b75f52124']: {
        hierarchies: [
          (data?.route.parents || []).map(
            (parent) => parent.object.breadcrumbText
          ),
        ],
      },
      ['k5b0552c8e051812b75f51f77']: {},
    },
    pageLoaded: !loading && product,
  });

  const addWishProps = {
    ProductComponent: GridProduct,
    className: 'product-page',
  };

  const firstAddwishResponse = {
    ...addWishResponseBoxItems['k5b05534de051812b75f52124'],
    ...addWishProps,
  };

  const secondAddWishResponse = {
    ...addWishResponseBoxItems['k5b0552c8e051812b75f51f77'],
    ...addWishProps,
  };


  if(product?.articleNumber === 'hemleverans') {
    return <Redirect to="/" />
  }

  if (!cacheHit)
    return (
      <ProductContainer>
        <ProductPageLoadingState />
      </ProductContainer>
    );

  return (
    <Fragment>
      <ProductContainer>
        <MaxWidth>
          <StyledBreadcrumbs
            parents={parents}
            breadcrumbText={product.breadcrumbText}
          />
        </MaxWidth>
        <Container>
          <ProductImageSection>
            {product.price &&
              product.previousPrice.incVat !== product.price.incVat && (
                <PricePromotion
                  previousPrice={product.previousPrice.incVat}
                  price={product.price.incVat}
                  isProductPage="true"
                />
              )}

            <ImageContainer
              images={images}
              video={video}
              badges={product.badges}
            />
            <Above breakpoint="md">
              {() => (
                <ProductToolbar
                  product={product}
                  variant={selectedVariation}
                  baseUrl={baseUrl}
                />
              )}
            </Above>
          </ProductImageSection>
          <ProductDetailsSection>
            <ProductDetailsWrapper>
              <React.Fragment>
                <ProductInfo>
                  <Left>
                    <Heading
                      data-testid="product-title"
                      dangerouslySetInnerHTML={{
                        __html: product.name || 'Laddar...',
                      }}
                    />
                    <ArticleNumber>
                      {selectedVariation?.articleNumber ??
                        product.articleNumber}
                    </ArticleNumber>
                    <ShortDescription
                      dangerouslySetInnerHTML={{
                        __html: product?.shortDescription,
                      }}
                    />
                  </Left>

                  <Right>
                    <div className="price-wrapper">
                      <Price includeVat={incVat} {...price} />
                      <span
                        className={
                          (selectedVariation || product).price &&
                          (selectedVariation || product).previousPrice
                            .incVat !==
                            (selectedVariation || product).price.incVat
                            ? 'suffix sale'
                            : 'suffix'
                        }
                      >
                        /{product.quantitySuffix}
                      </span>
                    </div>

                    {product.id && (
                      <ProductRatings
                        productPage={true}
                        product={product}
                        handleClick={handleClick}
                      />
                    )}
                  </Right>
                </ProductInfo>
                {product.hasVariants && (
                  <VariantSelector
                    product={product}
                    variantHandler={variantHandler}
                    productStockStatus={productStockStatus}
                  />
                )}

                <AddToCartForm
                  product={product}
                  variant={selectedVariation}
                  getMissingOptions={getMissingOptions}
                  variantHandler={variantHandler}
                  productStockStatus={productStockStatus}
                />

                {productStockStatus && (
                  <StockStatus stockStatus={productStockStatus} />
                )}

                <UspWrapper>
                  {isWeAhl ? (
                    <>
                      <IconTextWrapper>
                        <Link to="/kopvillkor">
                          <UspIcon icon={['fal', 'check']} /> SNABB LEVERANS
                        </Link>
                      </IconTextWrapper>
                      <IconTextWrapper>
                        <Link to="/kopvillkor">
                          <UspIcon icon={['fal', 'check']} /> PRISGARANTI
                        </Link>
                      </IconTextWrapper>
                      <IconTextWrapper>
                        <Link to="/kopvillkor">
                          <UspIcon icon={['fal', 'check']} /> FRAKTFRITT ÖVER
                          500:-
                        </Link>
                      </IconTextWrapper>
                    </>
                  ) : (
                    <>
                      <IconTextWrapper>
                        <Link to="/kopvillkor">
                          <UspIcon icon={['fal', 'check']} /> Storleksgaranti
                        </Link>
                      </IconTextWrapper>
                      <IconTextWrapper>
                        <Link to="/kopvillkor">
                          <UspIcon icon={['fal', 'check']} /> Prisgaranti
                        </Link>
                      </IconTextWrapper>
                      <IconTextWrapper>
                        <Link to="/kopvillkor">
                          <UspIcon icon={['fal', 'check']} /> Snabb leverans
                        </Link>
                      </IconTextWrapper>
                    </>
                  )}
                </UspWrapper>
                <ProductPaymentWrapper>
                  <Link to="/kopvillkor">
                    <img
                      src="https://cdn.klarna.com/1.0/shared/image/generic/badge/sv_se/checkout/short-blue.png?width=250&tool=widget"
                      alt="Betalningsalternativ"
                      loading="lazy"
                    />
                  </Link>
                </ProductPaymentWrapper>
              </React.Fragment>
            </ProductDetailsWrapper>
          </ProductDetailsSection>

          <Below
            breakpoint="md"
            render={() => (
              <ProductToolbar
                product={product}
                variant={selectedVariation}
                baseUrl={baseUrl}
              />
            )}
          />

          <Above breakpoint="md">
            {(matches) =>
              matches ? (
                <div id="product-tabs">
                  <ProductTabs
                    clickIndex={clickIndex}
                    activeTabPassed={activeTab || false}
                  >
                    <div label="Beskrivning">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: product.description,
                        }}
                      />
                    </div>
                    {tabContent &&
                      tabContent.map((tab, index) => {
                        return (
                          <div label={tab.label} key={index}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: tab.content,
                              }}
                            />
                          </div>
                        );
                      })}

                    {product.customFields &&
                      product.customFields.find(
                        (field) =>
                          field.title.toLowerCase() === 'passar modeller'
                      ) && (
                        <div label="Passar till" product={product}>
                          {product.customFields &&
                            product.customFields.map((field, index) => {
                              return (
                                <React.Fragment key={index}>
                                  {isUniversal?.boolValue && (
                                    <strong>Universal</strong>
                                  )}
                                  {field.title.toLowerCase() ===
                                    'passar modeller' && (
                                    <ModelList items={field.items} />
                                  )}
                                </React.Fragment>
                              );
                            })}
                        </div>
                      )}
                    {product?.relatedProducts?.length > 0 && (
                      <div label="Relaterade produkter" product={product}>
                        <ProductGrid
                          products={product.relatedProducts}
                          ProductComponent={GridProduct}
                        />
                      </div>
                    )}
                    {!loading && !isWeAhl && (
                      <div label="Recensioner" index="5">
                        <div>
                          <ProductReviews product={product} />
                        </div>
                      </div>
                    )}
                    {!loading && !isWeAhl && (
                      <div label="Frågor om produkten" index="6">
                        <div>
                          <Questions product={product} />
                        </div>
                      </div>
                    )}
                  </ProductTabs>
                </div>
              ) : (
                <ProductInfoAccordion product={product}>
                  {tabContent &&
                    tabContent.map((tab, index) => {
                      return (
                        <div label={tab.label} key={index}>
                          <div
                            dangerouslySetInnerHTML={{ __html: tab.content }}
                          />
                        </div>
                      );
                    })}
                  {product.customFields &&
                    product.customFields.find(function (field) {
                      return field.title.toLowerCase() === 'passar modeller';
                    }) && (
                      <div label="Passar till" product={product}>
                        {product.customFields &&
                          product.customFields.map((field, index) => {
                            return (
                              <React.Fragment key={index}>
                                {field?.title.toLowerCase() ===
                                  'passar modeller' && (
                                  <ModelList items={field?.items} />
                                )}
                              </React.Fragment>
                            );
                          })}
                      </div>
                    )}
                  {product?.relatedProducts?.length > 0 && (
                    <div label="Relaterade produkter" product={product}>
                      <ProductGrid
                        products={product.relatedProducts}
                        ProductComponent={GridProduct}
                      />
                    </div>
                  )}
                  {!loading && !isWeAhl && (
                    <div label="Recensioner" index="5">
                      <div>
                        <ProductReviews product={product} />
                      </div>
                    </div>
                  )}
                  {!loading && !isWeAhl && (
                    <div label="Frågor om produkten" index="5">
                      <div>
                        <Questions product={product} />
                      </div>
                    </div>
                  )}
                </ProductInfoAccordion>
              )
            }
          </Above>
          {product.id && <YotpoProductAlbum productId={product.id} />}
          {firstAddwishResponse?.items?.length > 0 && (
            <AddWishWrapper>
              <AddWishTitle>Andra köpte också</AddWishTitle>
              <AddWishProducts {...firstAddwishResponse} />
            </AddWishWrapper>
          )}
          {secondAddWishResponse?.items.length > 0 && (
            <AddWishWrapper>
              <AddWishTitle>Liknande produkter</AddWishTitle>
              <AddWishProducts {...secondAddWishResponse} />
            </AddWishWrapper>
          )}
        </Container>
      </ProductContainer>
    </Fragment>
  );
};

class ModelList extends React.Component {
  state = {
    items: [],
  };

  filterByParent = (items, parentId) => {
    return items.filter(function (item) {
      return item.parentId === parentId;
    });
  };

  getItemById = (id, items) => {
    for (var i = 0; i < items.length; i++) {
      if (items[i].id === id) {
        return items[i];
      }
    }
  };

  componentDidMount() {
    var items = this.props.items;

    var newItems = [];
    for (var i = 0; i < items.length; i++) {
      if (items[i].parentId === null) {
      } else {
        if (this.getItemById(items[i].parentId, items).title === 'Märke') {
        } else {
          var parent = this.getItemById(items[i].parentId, items);
          var grandParent = this.getItemById(parent.parentId, items);

          var newItem = {
            model: items[i].value,
            year: parent.value,
            brand: grandParent?.value,
          };
          newItems.push(newItem);
        }
      }
    }
    newItems.sort(function (a, b) {
      if (a.brand > b.brand) {
        return 1;
      } else if (a.brand < b.brand) {
        return -1;
      }

      if (a.model > b.model) {
        return 1;
      } else if (a.model < b.model) {
        return -1;
      }

      if (a.year < b.year) {
        return -1;
      } else if (a.year > b.year) {
        return 1;
      }
      return 1;
    });
    this.setState({ items: newItems });
  }

  render() {
    return (
      <ModelTable>
        <tbody>
          {this.state.items.map((item, index) => {
            if (!yearRegex.test(item.year)) {
              return <></>;
            }
            if (index === 0) {
              return (
                <>
                  <tr>
                    <th>{item.brand}</th>
                    <td />
                    <td />
                  </tr>
                  <tr>
                    <td>{item.model}</td>
                    <td>{item.year}</td>
                  </tr>
                </>
              );
            }
            if (item.brand !== this.state.items[index - 1].brand) {
              return (
                <tr>
                  <th>{item.brand}</th>
                  <td />
                  <td />
                </tr>
              );
            }
            return (
              <tr>
                <td>{item.model}</td>
                <td>{item.year}</td>
              </tr>
            );
          })}
        </tbody>
      </ModelTable>
    );
  }
}

function extractTabs(content) {
  var tabsObject = [];
  var htmlObject = document.createElement('div');
  htmlObject.innerHTML = content;

  if (!htmlObject.getElementsByClassName('tab-titles').length) {
    return false;
  }
  if (!htmlObject.getElementsByClassName('tab-content').length) {
    return false;
  }

  var titles = htmlObject
    .getElementsByClassName('tab-titles')[0]
    .getElementsByClassName('title');
  var tabs = htmlObject
    .getElementsByClassName('tab-content')[0]
    .getElementsByClassName('tab-content');

  for (var i = 0; i < titles.length; i++) {
    if (tabs[i]) {
      if (!tabs[i].getElementsByTagName('img')) {
        tabsObject.push({
          label: titles[i].innerText,
          content: tabs[i].innerText,
        });
      } else {
        tabsObject.push({
          label: titles[i].innerText,
          content: tabs[i].innerHTML,
        });
      }
    }
  }

  return tabsObject;
}

function useProductTabContent(description) {
  const [content, setContent] = useState(null);

  useEffect(() => {
    const extracted = extractTabs(description);
    if (extracted) {
      setContent(extracted);
    }
  }, [description]);

  return content;
}

export default Product;
