import ProductGrid from '@jetshop/ui/ProductList/ProductGrid';
import theme from '@jetshop/ui/utils/theme';
import styled from 'react-emotion';
import { Price } from '../Price';

const StyledProductGrid = styled(ProductGrid)`
  /* Override base UI components in ProductGrid */
  margin-left: -10px;
  margin-right: -10px;
  font-family: 'Abel', sans-serif !important;
  &.addwish-grid.product-page {
    width: 100%;
    margin: 0;
    > div:first-child {
      padding-left: 0;
    }
    > div:last-child {
      padding-right: 0;
    }
  }
  ${theme('below.lg')} {
    margin: 0;
    padding: 0 5px;
  }

  ${Price.New} {
    color: ${theme('colors.red')};
  }
  ${Price.Old} {
    color: #adadad;
  }
  > div {
    width: 25%;
    position: relative;
    padding: 10px;

    td,
    th {
      font-size: 14px;
      padding: 5px;
      border-bottom: 1px solid #f4f4f4;
    }

    ${theme('below.lg')} {
      width: 50%;
      padding: 5px;
      margin: 0;
    }
  }
`;

export default StyledProductGrid;
