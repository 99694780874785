import React, { Component } from 'react';
import Thumbnails from './Thumbnails';
import Fullsize from './Fullsize';
import LightBox from './LightBox';
import MobileSlider from './MobileSlider';
import styled from 'react-emotion';
import { Above } from '@jetshop/ui/Breakpoints';
import theme from '@jetshop/ui/utils/theme';
import ImageMissing from '../../../img/image-missing.jpg';
import Badges from '@jetshop/ui/ProductList/Badges';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: row;
  .lightboxTrigger {
    width: 100%;
    position: relative;
  }
`;

const PlaceHolder = styled('div')`
  img {
    max-width: 100%;
    height: auto;
  }
  ${theme('above.md')} {
    min-height: 500px;
  }
`;

const BadgesWrapper = styled('div')`
  > div {
    height: auto;
    width: auto;
  }
`;

export default class ImageContainer extends Component {
  static defaultProps = {
    images: [],
    className: ''
  };

  state = {
    selectedImage: 0,
    lightboxOpen: false,
    lightboxVideo: false
  };

  selectImage = i => {
    this.setState({
      selectedImage: i
    });
  };

  openLightbox = () => {
    this.setState({
      lightboxOpen: true
    });
  };
  closeLightbox = () => {
    this.setState({
      lightboxOpen: false,
      lightboxVideo: false
    });
  };
  videoLightbox = video => {
    this.setState({
      lightboxOpen: true,
      lightboxVideo: video
    });
  };

  render() {
    const { images, className, video, badges } = this.props;

    return images.length > 0 ? (
      <Above breakpoint="md">
        {matches =>
          matches ? (
            <Wrapper className={className}>
              <div className="lightboxTrigger" onClick={this.openLightbox}>
                <BadgesWrapper>
                  <Badges className="badges" badges={badges} />
                </BadgesWrapper>
                <Fullsize
                  image={this.props.images[this.state.selectedImage]}
                  images={images}
                  photoIndex={this.state.selectedImage}
                />
              </div>
              <Thumbnails
                video={video}
                images={images}
                selectImage={this.selectImage}
                videoLightbox={this.videoLightbox}
              />
              {this.state.lightboxOpen && (
                <LightBox
                  images={images}
                  isOpen={this.state.lightboxOpen}
                  activeIndex={this.state.selectedImage}
                  closeLightbox={this.closeLightbox}
                  selectImage={this.selectImage}
                  lightboxVideo={this.state.lightboxVideo}
                />
              )}
            </Wrapper>
          ) : (
            <Wrapper className={className}>
              <BadgesWrapper>
                <Badges className="badges" badges={badges} />
              </BadgesWrapper>
              <MobileSlider
                images={images}
                activeIndex={this.state.selectedImage}
                selectImage={this.selectImage}
                mobileSliderVideo={video}
              />
            </Wrapper>
          )
        }
      </Above>
    ) : (
      <PlaceHolder>
        <img src={ImageMissing} alt="Produktbild saknas" />
      </PlaceHolder>
    );
  }
}
