import React from 'react';
import styled from 'react-emotion';
import SubscribeToNewsletter from '@jetshop/core/components/Mutation/SubscribeToNewsletter';
import t, { Intl } from '@jetshop/intl';

const Wrapper = styled('div')`
  position: relative;
  width: 100%;
  margin: 1rem 0;
  background: white;
  height: 2.5rem;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  flex: 0 1 20rem;

  ${({ theme }) => theme.above.md} {
    margin-bottom: 2rem;
  }

  form,
  input {
    height: 100%;
    width: 100%;
  }

  input {
    background: transparent;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 4rem 0 1rem;
    border: 1px solid #bfbdbd;
  }
  button {
    all: unset;
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.red};
    border: 0;
    outline: none;
    position: absolute;
    right: 0;
    text-align: right;
    top: 0;
    height: 100%;
    cursor: pointer;
    padding: 0 1rem;
    text-transform: uppercase;
  }
`;

const Error = styled('div')`
  margin-bottom: 0.5rem;
  color: white;
`;

// Prevent the normal form submission behaviour, then call the
// onSubmit provided by the mutation
const submitEmail = (email, onSubmit) => e => {
  e.preventDefault();
  onSubmit(email);
};

class NewsletterField extends React.Component {
  state = {
    email: '',
    completed: false,
    error: false
  };

  // State change methods
  updateEmail = e =>
    this.setState({ email: e.currentTarget.value, error: false });
  setCompleted = () => this.setState({ completed: true });
  setError = () => this.setState({ error: true });
  unsetError = () => this.setState({ error: false });

  render() {
    return (
      <SubscribeToNewsletter
        onCompleted={this.setCompleted}
        onError={this.setError}
      >
        {(subscribe, result) => (
          <React.Fragment>
            {this.state.error ? (
              <Error>
                {t(
                  'Något gick snett. Vänligen kolla din epost-adress och försök igen. Eventuellt är du redan prenumerant.'
                )}
              </Error>
            ) : (
                ''
              )}
            <Wrapper>
              {/* Show the completion success message once signed up, otherwise show the form */}
              {this.state.completed ? (
                <p style={{ color: '#000' }}>{t('Du är nu registrerad')}</p>
              ) : (
                  <form onSubmit={submitEmail(this.state.email, subscribe)}>
                    <Intl>
                      {/* This is necessary to use t for the placeholder string */}
                      {t => (
                        <input
                          type="email"
                          placeholder="Ange e-postadress"
                          onChange={this.updateEmail}
                        />
                      )}
                    </Intl>
                    <button>
                      {/* Display 'Submitting…' while the form is posting */}
                      {result.loading ? 'Skickar…' : 'Skicka'}
                    </button>
                  </form>
                )}
            </Wrapper>
          </React.Fragment>
        )}
      </SubscribeToNewsletter>
    );
  }
}

export default NewsletterField;
