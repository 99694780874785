import React from 'react';
import styled from 'react-emotion';
import theme from '@jetshop/ui/utils/theme';
import { Below } from '@jetshop/ui/Breakpoints';

import SearchFieldBehaviour from './SearchFieldBehaviour';
import SearchInput from '../../../../components/Layout/Header/Search/SearchInput';
import SearchFlyout from '../../../../components/Layout/Header/Search/SearchFlyout';

export const FlyoutContainer = styled('div')`
  position: relative;
  button {
    margin-left: 1rem;
    background: none;
    border: 0;
    box-shadow: 0;
    cursor: pointer;
    color: white;
    font-weight: 500;
    display: flex;
    align-items: center;
    left: 100%;
    top: 0;
    position: absolute;
    height: 100%;
    :focus,
    active {
      outline: none;
    }
    &:hover {
      opacity: 0.8;
    }
    ${({ theme }) => theme.below.sm} {
      left: auto;
    }
  }
`;

export const Cover = styled('div')`
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 1;
  ${theme('below.md')} {
    background: rgba(0, 0, 0, 0.5);
  }
`;

const SearchField = ({
  className = '',
  isOpen,
  onOpen,
  toggleMobileSearch,
  ...searchFieldProps
}) => {
  return (
    <SearchFieldBehaviour
      {...searchFieldProps}
      onSubmit={(searchFieldProps.onCancel, toggleMobileSearch)}
    >
      {({
        getInputProps,
        getFlyoutProps,
        getCancelProps,
        updateFocus,
        ...rest
      }) => {
        return (
          <React.Fragment>
            <SearchInput
              getInputProps={getInputProps}
              toggleMobileSearch={toggleMobileSearch}
              {...rest}
              isOpen={isOpen}
            />

            <SearchFlyout
              getFlyoutProps={getFlyoutProps}
              closeSearch={searchFieldProps.closeSearch}
              searchFieldProps={searchFieldProps}
              {...rest}
            />
          </React.Fragment>
        );
      }}
    </SearchFieldBehaviour>
  );
};

export default SearchField;
