import { useMutation } from '@apollo/react-hooks';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faShoppingCart, faTimes } from '@fortawesome/pro-light-svg-icons';
import { CartIdContext } from '@jetshop/core/components/Cart/CartIdContext';
import { addToCartSuccess } from '@jetshop/core/components/Mutation/AddToCart/addToCartUtils';
import React, { useContext } from 'react';
import { addToCart } from '../Cart/addToCart.gql';
import { CartIcon } from './Product'

library.add(faShoppingCart, faTimes);

export function AddToCart({ product }) {
  const { cartId, setCartId } = useContext(CartIdContext);

  const articleNumber = product.variant?.articleNumber || product.articleNumber;

  const [add] = useMutation(addToCart, {
    variables: {
      input: {
        cartId,
        articleNumber,
        quantity: 1
      }
    },
    onCompleted: data => addToCartSuccess({ cartId, setCartId })({ data })
  });

  return (
    <button style={{ background: 'transparent' }} onClick={add}>
      <CartIcon icon={['fal', 'shopping-cart']} />
    </button>
  );
}
