import * as translations from '../translations';
import channelsQuery from './ChannelsQuery.gql';
import { addwishTracker } from '@jetshop/flight-addwish';
import CategoriesQuery from './components/Layout/Header/Categories.gql';
import StockStatusCategoryQuery from './components/ProductPage/StockStatusCategoryQuery.gql';

export default {
  apolloConfig: {
    shopid: process.env.REACT_APP_SHOP_ID || 'skoterdelen',
    graphQLURI:
      process.env.REACT_APP_GRAPHQL_URI || 'https://storeapi.jetshop.io',
    token:
      process.env.REACT_APP_APOLLO_TOKEN ||
      '359fd7c1-8e72-4270-b899-2bda9ae6ef57',
    channelsQuery,
    persistedQueries: [
      {
        query: StockStatusCategoryQuery,
        variables: { id: 12887 },
      },
    ],
  },
  trackingID: process.env.REACT_APP_GA_TRACKING_ID || 'UA-123334166-1',
  tagManagerID: process.env.REACT_APP_GTM_CONTAINER_ID,
  intl: {
    translations,
    defaultLocale: 'en',
    options: {
      formats: {
        number: {
          EUR: { style: 'currency', currency: 'EUR' },
          SEK: { style: 'currency', currency: 'SEK', minimumFractionDigits: 0 },
          USD: { style: 'currency', currency: 'USD' },
          GBP: { style: 'currency', currency: 'GBP' },
          NKR: { style: 'currency', currency: 'NKR' },
          AUD: { style: 'currency', currency: 'USD' },
        },
      },
    },
  },
  channelOverrides: {},
  singleDomainMode: false,
  disableGeoRedirect: true,
  routes: {
    login: '/login',
    myPages: '/my-pages',
    signup: '/signup',
  },
  yotpoKey: 'XSUDwJM4iTWs4ycz0OmoljxHroJKK9ea8txNXMUz',
  trackers: [addwishTracker('93A93A6F08B940E2590CF2D9504C867C')],
  deprecations: { useOldProductVariants: false, useOldFilterAPI: true },
};
