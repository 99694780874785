import React, { Fragment } from 'react';
import styled from 'react-emotion';
import t from '@jetshop/intl';

const Description = styled('article')`
  line-height: 25px;
`;

export const SectionHeading = styled('h2')`
  font-weight: 600;
  font-size: 0.875rem;
  margin-bottom: 1rem;
`;

export const ProductSection = styled('section')`
  width: 100%;
  padding-bottom: 3rem;

  ${({ theme }) => theme.below.md} {
    margin: 0;
  }
`;

const ProductInfo = ({ product }) => {
  return (
    <Fragment>
      <ProductSection>
        <SectionHeading>{t('Product description')}</SectionHeading>
        <Description
          dangerouslySetInnerHTML={{
            __html: product.description
          }}
        />
      </ProductSection>
      <ProductSection>
        <SectionHeading>{t('Specifications')}</SectionHeading>
      </ProductSection>
    </Fragment>
  );
};

export default ProductInfo;
