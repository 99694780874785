import OpenSans from './OpenSans-Regular.ttf';
import OpenSansBold from './OpenSans-SemiBold.ttf';

import Abel from './Abel-Regular.ttf';

import Caviar from './CaviarDreams.ttf';
import CaviarBold from './CaviarDreams_Bold.ttf';

import { injectGlobal } from 'emotion';

export default function loadCss() {
  return injectGlobal`
  @font-face {
    font-family: 'Open Sans';
    src: local('OpenSans-Regular'), local('OpenSans-Regular'),
        url(${OpenSans}) format('truetype');
    font-weight: normal;
    font-style: normal;

  @font-face {
    font-family: 'Open Sans';
    src: local('OpenSans-SemiBold'), local('OpenSans-SemiBold'),
        url(${OpenSansBold}) format('truetype');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'Abel';
    src: local('Abel-Regular'), local('Abel-Regular'),
        url(${Abel}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'CaviarDreams';
    src: local('CaviarDreams'), local('CaviarDreams'),
        url(${Caviar}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'CaviarDreams-Bold';
    src: local('CaviarDreams-Bold'), local('CaviarDreams-Bold'),
        url(${CaviarBold}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }


`;
}
