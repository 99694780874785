import styled from 'react-emotion';
import theme from '@jetshop/ui/utils/theme';

export default styled('main')`
  background: #f8f8f8;
  flex: 1 1 auto;
  padding-top: 165px;

  ${theme('above.md')} {
    [data-channel='Eyewearstore'] & {
      padding-top: 150px;
    }
  }

  ${theme('below.md')} {
    padding-top: 0;
  }
`;
