import Accordion, { AccordionContent } from '@jetshop/ui/Accordion/Accordion';
import CategoryLink from '@jetshop/ui/CategoryLink';
import React from 'react';
import styled, { css } from 'react-emotion';

import { Query } from 'react-apollo';

import SubCategoriesQuery from './SubCategories.gql';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleDown } from '@fortawesome/pro-light-svg-icons';
library.add(faAngleRight, faAngleDown);

export const activeCategoryLink = css`
  font-weight: 600;
`;

const handleClickAndPreventDefault = (e, handleClick, i) => {
  e.preventDefault();
  handleClick(i);
};

const Wrapper = styled('div')`
  position: relative;
`;

const NavigationIcon = styled(FontAwesomeIcon)`
  font-size: 1.5rem;
  color: black;
`;

const CategoryLinkToggle = styled(CategoryLink)`
  position: absolute;
  right: 0;
  top: 0;
  border: 0;
  padding: 11px 20px !important;
`;

export const CloseIcon = styled(FontAwesomeIcon)`
  font-size: 1.5rem;
  color: black;
  margin-right: 10px;
`;

const MobileCategories = ({ categories, closeMenu, parentCategory = null }) => {
  // This component is called recursively. On the first run through, we set up all categories
  // and their subcategories. On subsequent run-throughs (with parentCategory set), we set up
  // the accordion for just that parent Category and its children.
  if (parentCategory !== null && categories !== undefined) {
    categories = categories.filter(function (cat) {
      return cat.id !== parentCategory.id;
    });
  }
  if (categories) {
    return (
      <Accordion single>
        {({ handleClick, openIndexes }) =>
          categories.map((category, i) => (
            <Wrapper key={category.id}>
              {/* // Use AccordionContent */}
              <CategoryLink
                className={
                  openIndexes.includes(i) ? (activeCategoryLink, 'active') : ''
                }
                onClick={(e) => closeMenu()}
                category={category}
              >
                {category.name}
              </CategoryLink>

              {category.hasSubcategories && (
                <CategoryLinkToggle
                  className={
                    openIndexes.includes(i)
                      ? (activeCategoryLink, 'active')
                      : ''
                  }
                  onClick={(e) =>
                    // If this category has child categories, we want to open them up with `handleClick`
                    // Otherwise, we want to follow the link and `closeMenu`
                    category.hasSubcategories
                      ? handleClickAndPreventDefault(e, handleClick, i)
                      : closeMenu()
                  }
                  category={category}
                >
                  <React.Fragment>
                    {openIndexes.includes(i) ? (
                      <NavigationIcon icon={['fal', 'angle-down']} />
                    ) : (
                      <NavigationIcon icon={['fal', 'angle-right']} />
                    )}
                  </React.Fragment>
                </CategoryLinkToggle>
              )}

              <AccordionContent
                isOpen={openIndexes.includes(i)}
                className={openIndexes.includes(i) ? 'active' : ''}
              >
                {openIndexes.includes(i) && (
                  <SubCategories
                    categories={categories}
                    category={category}
                    closeMenu={closeMenu}
                  />
                )}
              </AccordionContent>
            </Wrapper>
          ))
        }
      </Accordion>
    );
  } else {
    return <div />;
  }
};

const SubCategories = ({ category, closeMenu }) => {
  return (
    <React.Fragment>
      <Query variables={{ root: category.id }} query={SubCategoriesQuery}>
        {(result) => {
          if (result.data === undefined) {
            return null;
          }
          return (
            <MobileCategories
              categories={result.data.categories}
              closeMenu={closeMenu}
              parentCategory={category}
            />
          );
        }}
      </Query>
    </React.Fragment>
  );
};

export default MobileCategories;
