import { useIntl } from '@jetshop/intl';
import { DropdownMenu, DropdownMenuButton, DropdownMenuItem, DropdownMenuItems } from '@jetshop/ui/DropdownMenu';
import React, { useState } from 'react';
import { css, cx } from 'react-emotion';
import Stockstatus1 from '../../img/stockstatus-1.png';
import Stockstatus2 from '../../img/stockstatus-2.png';
import Stockstatus3 from '../../img/stockstatus-3.png';
import Stockstatus4 from '../../img/stockstatus-4.png';
import { ReactComponent as Caret } from '../../svg/Carrot.svg';
import { getStockValue } from './useStocklevelCalc';
import { theme } from '../Theme'

const stockStatusIcons = {
  1: <img src={Stockstatus1} alt="" />,
  2: <img src={Stockstatus2} alt="" />,
  3: <img src={Stockstatus3} alt="" />,
  4: <img src={Stockstatus4} alt="" />,
}


export function VariantSelector({ product, variantHandler }) {

  const variantValues = product.variants.values
  const [selected, setSelected] = useState(null)

  const variantTable = variantValues.reduce((variants, variant) => {
    const [id] = variant.values;

    if (!variants[id]) {
      variants[id] = [];
    }

    variants[id].push(variant)

    return variants;
  }, {})


  return (
    <div className={sharedStyles}>
      {product.variants.options.map((option) => {
        return (
          <DropdownVariantSelect
            key={option.name}
            option={option}
            variantHandler={variantHandler}
            options={product.variants.options}
            levels={product.variants.options.length}
            variantTable={variantTable}
            setSelected={setSelected}
            selected={selected}
          />
        );
      })}
    </div>
  );
}

const sharedStyles = css`
  width: 100%;
  ${theme.above.md} {
    width: 72.5%;
  }
  label {
    display: flex;
    padding-bottom: 0.25em;
    margin-top: 1em;
    font-size: 0.875em;
    align-items: center;
  }
  .invalid {
    label {
      color: #eb5757;
    }
  }
  .missingVariant {
    margin-right: 0.25em;
    height: 10px;
    width: 10px;
  }
`;

function DropdownVariantSelect({
  option,
  variantHandler,
  options,
  levels,
  variantTable,
  setSelected,
  selected
}) {
  const { getSelectedValue, selectValue } = variantHandler;
  const t = useIntl();

  const selectedValue = getSelectedValue(option);
  const isLevelTwo = levels === 2 && options?.[1].name === option.name

  return (
    <div className={cx(dropdownStyles)}>
      <DropdownMenu>
        <DropdownMenuButton
          id={`option-${option.name}`}
          aria-label={option.name}
          data-testid="variantselector-button"
        >
          {selectedValue
            ? selectedValue
            : t.rich('{option}', { option: option.name })}
          <Caret />
        </DropdownMenuButton>
        <DropdownMenuItems style={{ zIndex: 9999 }}>
          {option.values.map((value, index) => {

            const single = levels === 1 && getStockValue(variantTable[value][0])

            const multiple = isLevelTwo && selected
              && getStockValue(variantTable[selected][index])

            return (
              <DropdownMenuItem
                key={value + option.name}
                data-testid={`variant-${index}`}
                onSelect={({ setIsOpen }) => {
                  selectValue(value, option);
                  setIsOpen(false);
                  !isLevelTwo && setSelected(value)
                }}
              >
                <span>{value}</span>
                <span>
                  {multiple && stockStatusIcons[multiple.icon]}
                  {single && stockStatusIcons[single.icon]}
                </span>
              </DropdownMenuItem>
            );
          })}
        </DropdownMenuItems>
      </DropdownMenu>
    </div>
  );
}

const dropdownStyles = css`
  [data-flight-dropdown] {
    background: white;
  }
  [data-flight-dropdown-button] {
    background: white;
    width: 100%;
    display: flex;
    align-items: center;
    border: 1px solid #cecece;
    height: 54px;
    font-weight: bold;
    margin-bottom: 12px;
    svg {
      width: auto;
    }
  }
  [data-flight-dropdown-open='true'] {
    [data-flight-dropdown-button] {
      border-bottom-color: transparent;
      border-radius: 3px 3px 0 0;
      svg {
        rotate: -180deg;
        transition: rotate .1s ease;
      }
    }
  }
  [data-flight-dropdown-items] {
    width: 100%;
    border: 1px solid #cecece;
    margin-top: -1px;
    border-radius: 0 0 3px 3px;
  }
  [data-flight-dropdown-item] {
    display: flex;
    align-items: center;
    height: 54px;
    justify-content: space-between;
    :last-child {
      border-radius: 0 0 3px 3px;
    }
  }
  &.invalid [data-flight-dropdown-button] {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  svg {
    margin-left: auto;
  }
`;
