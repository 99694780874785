import React from 'react';
import styled from 'react-emotion';
import theme from '@jetshop/ui/utils/theme';
import AddFavoriteButton, {
  FavoriteIcon,
} from '../ProductList/AddFavoriteButton';
import { useLocation } from 'react-router';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPrint } from '@fortawesome/pro-light-svg-icons';
library.add(faInstagram, faFacebook);
library.add(faEnvelope, faPrint);

const Toolbar = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: row !important;
  padding: 10px 0;

  ${theme('below.md')} {
    width: 100%;
    justify-content: center;
  }

  div {
    margin-right: 10px;

    ${FavoriteIcon} {
      position: relative;
      top: auto;
      left: auto;
      font-size: 2rem;
    }
  }
`;
const ToolbarIcon = styled(FontAwesomeIcon)`
  font-size: 2rem;
  color: #212121;
`;

const ProductToolbar = ({ product, variant, baseUrl }) => {
  const location = useLocation();

  return (
    <Toolbar>
      <div>
        <a
          href={`https://www.facebook.com/sharer/sharer.php?u=${baseUrl}${location.pathname}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <ToolbarIcon icon={['fab', 'facebook']} />
        </a>
      </div>
      <div>
        <a
          href={`mailto:?subject=${baseUrl}.com&body=Kolla in den här produkten: https://${baseUrl}${location.pathname}`}
        >
          <ToolbarIcon icon={['fal', 'envelope']} />
        </a>
      </div>
      <div>
        <AddFavoriteButton product={product} variant={variant} />
      </div>
    </Toolbar>
  );
};

export default ProductToolbar;
