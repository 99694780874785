import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';

import ProductTab from './ProductTab';

const TabWrapper = styled('div')`
  width: 100%;
  display: block;
  margin: 2rem auto;
`;
const TabList = styled('ol')``;
const TabContent = styled('div')`
  border: 1px solid #efefef;
  display: block;
  width: 100%;
  padding: 3rem;
  line-height: 1.3;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  background: #fff;
  p {
    margin-bottom: 1em;
  }
  .hide {
    display: none;
  }
`;

class Tabs extends PureComponent {
  static propTypes = {
    children: PropTypes.instanceOf(Array).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      activeTabPassed: true,
      activeTab: this.props.children[0].props.label,
    };
  }

  onClickTabItem = (tab) => {
    if (this.props.activeTabPassed) {
      this.setState({ activeTabPassed: false });
    }
    this.setState({ activeTab: tab });
  };

  componentDidUpdate(prevProps, nextProps) {
    if (
      this.props.activeTabPassed &&
      nextProps.activeTab !== this.props.activeTabPassed &&
      this.state.activeTabPassed !== false
    ) {
      this.setState({ activeTabPassed: true });
      this.setState({ activeTab: this.props.activeTabPassed });
    }

    if (prevProps.clickIndex < this.props.clickIndex) {
      this.setState({ activeTabPassed: true });
      this.setState({ activeTab: this.props.activeTabPassed });
    }
  }

  render() {
    const {
      onClickTabItem,
      props: { children },
      state: { activeTab },
    } = this;
    let newChildren = [];
    children.map((child, index) => {
      if (child) {
        if (child.length) {
          child.map((childLvl2) => {
            newChildren.push(childLvl2);
            return newChildren;
          });
        } else {
          newChildren.push(child);
        }
      }
      return child;
    });

    return (
      <TabWrapper className="tabs">
        <TabList className="tab-list">
          {newChildren.map((child) => {
            const { label } = child.props;

            if (child.props.children && child.props.children.length < 1) {
              return false;
            }
            return (
              <ProductTab
                activeTab={activeTab}
                key={label}
                label={label}
                onClick={onClickTabItem}
              />
            );
          })}
        </TabList>
        <TabContent className="tab-content">
          {newChildren.map((child) => {
            if (!child) {
              return false;
            }
            if (child[0]) {
              child = child[0];
            }
            if (child.props.label !== activeTab) return undefined;
            return child.props.children;
          })}
        </TabContent>
      </TabWrapper>
    );
  }
}

export default Tabs;
