import React from 'react';
import styled from 'react-emotion';

const dots = `
  @keyframes dots {
    0%,
    80%,
    100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
`;

const StyledSpinner = styled.div`
  text-align: center;
  margin-top: 2rem;
  > div {
    ${dots}
    width: ${(p) => (p.size ? p.size : 18)}px;
    height: ${(p) => (p.size ? p.size : 18)}px;
    border-radius: 100%;
    display: inline-block;
    background: ${(p) => p.color || '#383838'};
    animation: dots 1.4s infinite ease-in-out both;
  }
`;

const spinnTime = ['-0.32s', '-0.16s'];

const Spinner = ({ ...rest }) => (
  <StyledSpinner {...rest}>
    {spinnTime.map((spinn) => (
      <div key={spinn} style={{ animationDelay: spinn }} />
    ))}
  </StyledSpinner>
);

export default Spinner;
