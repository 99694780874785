import React from 'react';
import styled from 'react-emotion';
import MaxWidth from '../Layout/MaxWidth';
import theme from '@jetshop/ui/utils/theme';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
library.add(faCheck);

const StyledMaxWidth = styled(MaxWidth)`
  ${theme('below.sm')} {
    padding: 0;
  }
`;

const ExtraDetailsWrapper = styled('div')`
  width: 100%;
  [data-channel='Eyewearstore'] & {
    margin: 12px 0;
  }
`;

const MainContentWrapper = styled('div')`
  display: flex;
  justify-content: space-evenly;
  padding: 1rem 0 2rem 0;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  text-align: center;
  max-width: 1000px;
  margin: auto;
  width: 100%;
  ${theme('below.md')} {
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 0 10px;
    height: auto;
  }
`;

const IconTextWrapper = styled('div')`
  display: flex;
  width: 100%;
  text-align: center;
  justify-content: center;
  flex: 1;
  svg {
    color: #00b55b;
  }
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 18px;
    text-transform: uppercase;
    font-size: 1.2rem;
    color: #000;
    text-decoration: none;
    ${theme('below.md')} {
      font-size: 0.75rem;
    }
  }

  ${theme('below.md')} {
    width: auto;
    padding: 0.2rem 0.5rem;
    ${theme('below.md')} {
      padding: 0.2rem;
    }
  }
`;

export const UspIcon = styled(FontAwesomeIcon)`
  font-size: 2rem;
  margin-right: 10px;
`;

const ExtraDetails = ({ isWeAhl }) => {
  return (
    <ExtraDetailsWrapper>
      <StyledMaxWidth>
        <MainContentWrapper>
          {!isWeAhl && (
            <>
              <IconTextWrapper>
                <span>
                  <UspIcon icon={['fal', 'check']} /> Storleksgaranti
                </span>
              </IconTextWrapper>
              <IconTextWrapper>
                <span>
                  <UspIcon icon={['fal', 'check']} /> Prisgaranti
                </span>
              </IconTextWrapper>
              <IconTextWrapper>
                <span>
                  <UspIcon icon={['fal', 'check']} /> Snabb leverans
                </span>
              </IconTextWrapper>
            </>
          )}
        </MainContentWrapper>
      </StyledMaxWidth>
    </ExtraDetailsWrapper>
  );
};

export default ExtraDetails;
