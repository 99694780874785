import { useProductVariants } from '@jetshop/core/hooks/useProductVariants';
import { useProductList } from '@jetshop/core/hooks/ProductList';
import React from 'react';
import styled from 'react-emotion';
import theme from '@jetshop/ui/utils/theme';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faHeart as fasHeart } from '@fortawesome/free-solid-svg-icons';
import { faShoppingCart, faHeart } from '@fortawesome/pro-light-svg-icons';
library.add(faShoppingCart, faHeart, fasHeart);

const IconContainer = styled('div')`
  font-size: 1.5rem;
`;

export const FavoriteIcon = styled(FontAwesomeIcon)`
  position: absolute;
  left: 10px;
  top: 10px;
  font-size: 1.5rem;
  z-index: 1;
  color: #333;
  &:hover {
    color: ${theme('colors.red')};
  }
  &.red {
    color: ${theme('colors.red')};
  }
`;


function AddFavoriteButton({ product, variant, ...rest }) {
  const articleNumber = product.articleNumber;
  const { selectedVariant: selectedVariation } = useProductVariants(
    product?.hasVariant ? product : undefined
  );

  const variantArticleNumber = selectedVariation?.articleNumber;

  const { toggle, inList } = useProductList();

  function toggleWithoutBubble(e) {
    e.preventDefault();
    toggle(articleNumber, {
      variantArticleNumber
    });
  }

  const isInList = inList(articleNumber, {
    variantArticleNumber
  });

  const icon = isInList ? 'fas' : 'fal';

  return (
    <IconContainer>
      <button style={{ background: 'transparent' }} onClick={toggleWithoutBubble}>
        <FavoriteIcon
          icon={[icon, 'heart']}
          className={
            isInList ? 'red' : 'dark'
          }
        />
      </button>
    </IconContainer>
  );
}

export default AddFavoriteButton