import t from '@jetshop/intl';
import Accordion from '@jetshop/ui/Accordion/Accordion';
import BoolFilters from '@jetshop/ui/Filter/FilterTypes/BoolFilters';
import ListFilterAccordion from '@jetshop/ui/Filter/FilterTypes/List/ListFilterAccordion';
import RangeFilterAccordion from '@jetshop/ui/Filter/FilterTypes/Range/RangeFilterAccordion';
import SortOrderList from '@jetshop/ui/SortOrder/SortOrderList';
import theme from '@jetshop/ui/utils/theme';
import React, { Fragment } from 'react';
import styled from 'react-emotion';

const Title = styled('h3')`
  color: black;
  font-size: 1.125rem;
  text-align: center;
  padding: 1.5rem;
  border-bottom: 1px solid #e8e8e8;
  font-weight: 600;
  font-family: ${theme('fontFamilies.heavy')};
`;

const Wrapper = styled('div')`
  button {
    padding: 0 1rem;
    border-width: 1px 0 0 0;
  }
`;

export default ({ filters, sortOrders, close, ...props }) => {
  const listFilters = (filters || []).filter(
    filter => filter.__typename === 'ListFilter'
  );

  const rangeFilters = (filters || []).filter(
    filter => filter.__typename === 'NumericRangeFilter'
  );

  return (
    <section {...props}>
      {filters ? (
        <Wrapper>
          <Title>{t('Filter')}</Title>
          <Accordion single>
            {accordionProps => (
              <React.Fragment>
                <ListFilterAccordion
                  listFilters={listFilters}
                  accordionProps={accordionProps}
                />
                <RangeFilterAccordion
                  rangeFilters={rangeFilters}
                  accordionProps={accordionProps}
                  startingIdx={listFilters.length}
                />

                <div css={{ padding: '1rem' }}>
                  <BoolFilters filters={filters} />
                </div>
              </React.Fragment>
            )}
          </Accordion>
        </Wrapper>
      ) : (
        <Fragment>
          <Title>{t('Sort by')}</Title>
          <SortOrderList sortOrders={sortOrders} />
        </Fragment>
      )}
    </section>
  );
};
