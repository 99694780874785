import React, { Fragment, useState, useEffect } from 'react';
import styled from 'react-emotion';
import theme from '@jetshop/ui/utils/theme';
import { FlyoutTrigger } from '@jetshop/ui/Modal/Flyout';
import { Above } from '@jetshop/ui/Breakpoints';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import { ReactComponent as Snowmobile } from '../../svg/Snowmobile.svg';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { ModelSelectorConsumer } from './ModelSelectorProvider';
library.add(faCheck);

const Button = styled('button')`
  position: relative;
  padding: 0;
  background: transparent;
  color: inherit;
  border: 0;
  outline: none;
  margin-left: 20px;
  transition: all, 0.2s;
  ${theme('below.md')} {
    margin: 0;
    margin-top: 4px;
  }
  :hover {
    color: ${theme('colors.red')};
  }
  > svg {
    margin-right: 10px;
    font-size: 2rem !important;
    width: 2em;
    ${theme('below.md')} {
      width: 1.3em;
    }
  }
  div {
    display: inline-block;
    span {
      display: block;
      margin: 0;
      text-align: left;
      &:first-child {
        font-size: 24px;
      }
      &:last-child {
        font-size: 14px;
      }
    }
  }
`;

const Badge = styled('span')`
  background: ${theme('colors.green')};
  color: white;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 20px;
  position: absolute;
  top: 1px;
  right: 4px;
  font-size: 12px;
  &.disabled {
    background: ${theme('colors.red')};
  }
  ${theme('below.md')} {
    right: -7px;
    top: 4px;
    width: 15px;
    height: 15px;
    line-height: 15px;
  }
`;
const BadgeIcon = styled(FontAwesomeIcon)`
  font-size: 12px !important;
  margin: 0 !important;
`;

const StyledSnowmobile = styled(Snowmobile)`
  max-width: 70px;
  ${theme('below.md')} {
    max-width: 50px;
    margin-right: 0px !important;
  }
`;

const BadgeNum = ({ filterOn, hasFilter }) => {
  return (
    <Badge filterOn={hasFilter} className={hasFilter ? '' : 'disabled'}>
      <BadgeIcon icon={['fal', 'check']} />
    </Badge>
  );
};

const ModelsButton = ({ activeFilters }) => {
  const [ssrDone, setSsrDone] = useState(false);
  const [hasFilters, setHasFilters] = useState(false);
  var savedFilter = ssrDone && JSON.parse(localStorage.getItem('savedFilter'));

  useEffect(() => {
    // Update the document title using the browser API
    setSsrDone(true);
    if (
      activeFilters.multiListFilters &&
      activeFilters.multiListFilters.length > 0 &&
      activeFilters.multiListFilters[0].values.length === 3
    ) {
      if (!hasFilters) {
        setHasFilters(true);
      }
    } else {
      if (hasFilters) {
        setHasFilters(false);
      }
    }
  }, [ssrDone, activeFilters]);

  return (
    <ModelSelectorConsumer>
      {({ filterOn, toggleFilter }) => (
        <Above breakpoint="md">
          {(matches) =>
            matches ? (
              <FlyoutTrigger id="model-flyout">
                {(flyout) => (
                  <Button
                    onClick={
                      flyout.isOpen ? flyout.hideTarget : flyout.showTarget
                    }
                  >
                    <Fragment>
                      <StyledSnowmobile />
                    </Fragment>

                    <BadgeNum hasFilter={hasFilters} filterOn={filterOn} />
                  </Button>
                )}
              </FlyoutTrigger>
            ) : (
              <DrawerTrigger preventOverflow={true} id="model-drawer">
                {(drawer) => (
                  <Button
                    onClick={
                      drawer.isOpen ? drawer.hideTarget : drawer.showTarget
                    }
                  >
                    <Fragment>
                      <StyledSnowmobile />
                    </Fragment>

                    <BadgeNum
                      hasFilter={
                        activeFilters.multiListFilters &&
                        activeFilters.multiListFilters.length > 0 &&
                        activeFilters.multiListFilters[0].values.length === 3
                      }
                      filterOn={filterOn}
                    />
                  </Button>
                )}
              </DrawerTrigger>
            )
          }
        </Above>
      )}
    </ModelSelectorConsumer>
  );
};

export default ModelsButton;
