import React from 'react';
import styled from 'react-emotion';

const ImageWrapper = styled('div')`
  background: #fff;
  text-align: center;
  padding-top: 100%;
  position: relative;
  min-width: 625px;
  img {
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    left: 50%;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }
`;

const Fullsize = ({ image, onClick }) => {
  return image ? (
    <ImageWrapper>
      <img onClick={onClick} src={image.url} alt={image.url} />
    </ImageWrapper>
  ) : null;
};

export default Fullsize;
