import React from 'react';
import styled from 'react-emotion';
import MobileCategories from '../Categories/MobileCategories';
import t from '@jetshop/intl';
import Drawer, { DrawerTarget } from '@jetshop/ui/Modal/Drawer';

import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import { CloseIcon } from '../Header';
import theme from '@jetshop/ui/utils/theme';
import { TopBarLinks } from '../Desktop/DesktopHeader';

const MenuTitle = styled.button`
  font-weight: bold;
  font-size: 16px;
  margin: 16px;
  background: transparent;
  text-align: right;
  margin: 0;
  padding: 20px 10px;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: normal;
  text-transform: uppercase;

  svg {
    font-size: 2rem;
    margin-left: 10px;
  }
`;

const MobileNavigation = styled('div')`
  .extra-links {
    background: #f7f7f7;
    border-top: 1px solid #e0e0e0;
    > ul > li {
      border-bottom: 1px solid #e0e0e0;
      > a {
        padding: 15px;
      }
      ul {
        padding-left: 1rem;
        padding-bottom: 8px;
        a {
          padding: 8px 10px;
          font-size: 14px;
        }
      }
    }
  }

  > div {
    &:last-child {
      border-bottom: 1px solid #ddd;
    }

    a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0;
      text-transform: uppercase;
    }

    > a {
      // LINK
      padding: 15px;
      border-top: 1px solid #ddd;

      // LV2 WRAPPER
      + div {
        // LV2 ITEM
        > div > a,
        > a {
          background: #f4f4f4;
          font-size: 13px;
          padding: 10px 10px 10px 15px;
          border-top: 1px solid #ddd;
          height: 45px;

          + div > div > a {
            background: #eaeaea;
            font-size: 13px;
            padding: 10px 10px 10px 15px;
            height: 45px;
            border-bottom: 1px solid #e0e0e0;

            + div > div > a {
              background: #e0e0e0;
              font-size: 13px;
              padding: 10px 10px 10px 15px;
              height: 45px;
              border-bottom: 1px solid #d4d4d4;
            }
          }
        }
      }
    }
  }
`;

const MobileMenuModal = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  overflow: auto;

  &:focus {
    outline: none;
  }
  a {
    margin-left: 16px;
    margin-bottom: 16px;
    font-size: 16px;
    color: black;
    text-decoration: none;
  }
`;

export default function MobileMenu({ data, isWeAhl }) {
  if (!(data && data.categories)) return null;
  return (
    <DrawerTarget id="menu-drawer">
      {(drawer) => (
        <Drawer isOpen={drawer.isOpen}>
          <MobileMenuModal>
            <MenuTitle onClick={drawer.hideTarget}>
              <CloseIcon icon={['fal', 'times']} />
              <span>{t('Stäng meny')}</span>
            </MenuTitle>
            <MobileNavigation>
              <MobileCategories
                categories={data.categories}
                closeMenu={drawer.hideTarget}
              />
              {!isWeAhl && (
                <div className="extra-links">
                  <TopBarLinks />
                </div>
              )}
            </MobileNavigation>
          </MobileMenuModal>
        </Drawer>
      )}
    </DrawerTarget>
  );
}
