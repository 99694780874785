import React, { Component } from 'react';
import styled from 'react-emotion';

export const ReviewWrapper = styled('div')`
  width: 100%;
  display: block;
  overflow-x: hidden;
  .yotpo-no-reviews,
  .yotpo-display-wrapper:not(.yotpo-pictures-widget) {
    display: none !important;
  }
`;

class YotpoProductAlbum extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false
    };
  }
  componentDidMount() {
    if (typeof window !== 'undefined') {
      setTimeout(() => {
        if (document.getElementsByClassName('yotpo-reviews').length > 0) {
          this.init();
        }
      }, 200);
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.yotpo = undefined;
      window.removeEventListener('load', this.init);
    }
  }

  init = () => {
    var e = document.createElement('script');
    e.type = 'text/javascript';
    e.async = true;
    var apiKey = 'XSUDwJM4iTWs4ycz0OmoljxHroJKK9ea8txNXMUz';
    e.src = 'https://staticw2.yotpo.com/' + apiKey + '/widget.js';
    e.defer = true;
    e.id = 'yotpoScript';
    var t = document.getElementsByTagName('script')[0];
    t.parentNode.insertBefore(e, t);
    this.setState({ loaded: true });
    if (typeof window !== 'undefined') {
      if (
        window.yotpo !== undefined &&
        yotpo.widgets &&
        yotpo.widgets.length > 0
      ) {
        var api = new Yotpo.API(yotpo);
        api.refreshWidgets();
      }
    }
  };
  render() {
    if (typeof window !== 'undefined') {
      if (
        this.state.loaded &&
        window.yotpo !== undefined &&
        yotpo.widgets &&
        yotpo.widgets.length > 0
      ) {
        var api = new Yotpo.API(yotpo);
        api.refreshWidgets();
      }
    }
    return (
      <ReviewWrapper>
        <div
          className="yotpo yotpo-pictures-widget"
          data-gallery-id="5d8c5410ef9c5c1d3db34a32"
          data-product-id={this.props.articleNumber}
        ></div>
      </ReviewWrapper>
    );
  }
}

export default YotpoProductAlbum;
