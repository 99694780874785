import React from 'react';
import styled from 'react-emotion';

import theme from '@jetshop/ui/utils/theme';

import Image from '@jetshop/ui/Image';

import ThumbnailsSlider from './ThumbnailsSlider';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
library.add(faPlay);

const ProductImage = styled(Image)`
  background-size: auto;
  background-position: center;

  background-color: white;
  ${theme('below.md')} {
  }
`;

const Wrapper = styled('div')`
  width: 20%;
  ${theme('below.md')} {
    width: 100%;
  }
  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: -3px;
    ${theme('below.md')} {
      flex-direction: row;
      flex-wrap: wrap;
      margin-left: -5px;
      margin-right: -5px;
      margin-top: 5px;
    }
    li {
      display: block;
      width: 100%;
      border: 3px solid #f8f8f8;
      border-right: none;
      margin-top: 0;
      height: 5rem;
      width: 5rem;
      background-size: contain;
      background-position: center;
      cursor: pointer;
      text-align: center;
      position: relative;
      color: #fff;
      background-color: white;
      svg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        margin: auto;
        font-size: 2rem;
      }
      :hover {
        opacity: 0.9;
      }
      ${theme('below.md')} {
        width: 20%;
        margin: 5px;
        flex-grow: 1;
        background-size: contain;
      }
    }
    .video-li {
      position: relative;
      &:before  {
        content: '';
        background: rgba(255, 255, 255, 0.7);
        position: absolute;
        top: 0;
        right: 0;
        bottom: -3px;
        left: 0;
        z-index: 1;
      }
      &:after {
        content: '';
        height: 35px;
        width: 35px;
        border-radius: 100%;
        border: 2px solid black;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        margin: auto;
      }
      svg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        margin: auto;
        z-index: 1;
        font-size: 1rem;
        color: black;
      }
    }
  }
`;

/**
 * @param {array} {images} Array of prod images
 */
const Thumbnails = ({ images, selectImage, video, videoLightbox }) => {
  // sizes[]:  height, url, width

  const prodImages = images || [];

  return prodImages.length > 7 ? (
    <Wrapper>
      <ThumbnailsSlider
        images={prodImages}
        video={video}
        videoLightbox={videoLightbox}
        selectImage={selectImage}
      />
    </Wrapper>
  ) : (
    <Wrapper>
      {prodImages.length > 0 ? (
        <ul>
          {prodImages.map((img, i) => (
            <li
              key={i}
              onClick={() => selectImage(i)}
              css={{
                background: `url(${
                  img.sizes &&
                  img.sizes[1] &&
                  img.sizes[1].url + '?method=thumbnail&width=300&height=auto'
                }) center center no-repeat`,
              }}
            >
              <ProductImage
                onClick={() => selectImage(i)}
                aspect="1:1"
                src={img.url}
              />
            </li>
          ))}
          {video && (
            <li
              key={images[0].url}
              onClick={() => videoLightbox(video)}
              className="video-li"
            >
              <FontAwesomeIcon icon={['fa', 'play']} />
              <ProductImage aspect="1:1" src={images[0].url} />
            </li>
          )}
        </ul>
      ) : null}
    </Wrapper>
  );
};

export default Thumbnails;
