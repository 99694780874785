import { Above, Between } from '@jetshop/ui/Breakpoints';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import theme from '@jetshop/ui/utils/theme';
import React from 'react';
import { useQuery } from 'react-apollo';
import styled from 'react-emotion';
import { Link } from 'react-router-dom';
import MaxWidth from '../MaxWidth';

import FilterContext from '@jetshop/core/components/Filters/FilterContext';
import CartButton from '../../Cart/CartButton';
import CartFlyout from '../../Cart/CartFlyout';
import ModelsButton from '../../ModelSelector/ModelButton';
import ModelFlyout from '../../ModelSelector/ModelFlyout';
import FavoritesButton from '../../ProductList/FavoritesButton';
import { FavoritesFlyout } from '../../ProductList/Favourites';
import homeCategoriesQuery from './Categories.gql';
import DesktopHeader, { WeAhlUsp } from './Desktop/DesktopHeader';
import MobileMenu from './Mobile/MobileMenu';

import { useScroll } from './useScroll';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBars,
  faEnvelope,
  faHeart,
  faMobileAlt,
  faSearch,
  faShoppingCart,
  faTimes,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Logo from '../../../img/logo.png';
import { ReactComponent as Eyewearstore } from '../../../svg/Eyewearstore-logo.svg';
import SearchField from './Search/SearchField';
library.add(
  faHeart,
  faShoppingCart,
  faMobileAlt,
  faEnvelope,
  faSearch,
  faBars,
  faTimes
);

const Container = styled('header')`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1001;

  &[data-channel='Eyewearstore'] {
    background: ${theme('colors.lightGrey')};
    [data-sticky-header='true'] & {
      background: transparent;
    }
  }

  a {
    text-decoration: none;
    color: inherit;

    &:hover {
      text-decoration: underline;
      color: #000;
    }
  }
  ${theme('below.md')} {
    height: auto;
    background: #212121;
    position: relative;
  }

  .mobile-nav-drawer {
    background: #212121;
    color: white;
  }
`;

const HeaderContainer = styled('div')`
  height: 54px;
  position: relative;
  z-index: 99;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #000000;
  [data-channel='Eyewearstore'] & {
    background-color: #ffffff;
  }

  [data-sticky-header='true'] & {
    position: fixed;
    transform: translateY(60px);
    transition: all 0.4s;
    top: -60px;
  }
`;

export const MenuButton = styled('button')`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  background: transparent;
  align-items: center;
  svg {
    height: 18px;
    width: 18px;
    [data-channel='Eyewearstore'] & {
      color: black;
    }
  }
  :focus,
  :active,
  :hover {
    outline: none;
    opacity: 0.8;
  }
  label {
    margin-top: 3px;
  }
`;

export const IconContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  margin: 0;
`;

export const MenuTitle = styled.div`
  font-weight: bold;
  font-size: 16px;
  margin: 16px;
  ${theme('below.md')} {
    text-align: right;
    margin: 0;
    padding: 20px 10px;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: normal;
    text-transform: uppercase;

    svg {
      font-size: 2rem;
      margin-left: 10px;
    }
  }
`;

export const CloseIcon = styled(FontAwesomeIcon)`
  font-size: 1.5rem;
  color: black;
  margin-right: 10px;
`;

const MenuIcon = styled(FontAwesomeIcon)`
  font-size: 1.5rem;
  color: white;
`;

const HeaderItemsContainer = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  > div {
    display: flex;
    align-items: center;
  }

  .left-menu {
    margin-right: auto;
    flex: 1;
  }
  .center-menu {
    img {
      max-width: 150px;
    }
    .eyewearstore-logo {
      max-width: 170px;
      width: 100%;
      margin-top: 6px;
      margin-right: 8px;
    }
    ${theme('below.sm')} {
      img {
        max-width: 120px;
      }
      .eyewearstore-logo {
        width: 100%;
      }
    }
  }
  .right-menu {
    margin-left: auto;
    flex: 1;
    justify-content: flex-end;
    > button {
      padding: 0;
    }
    > * {
      margin-left: 16px;
    }
  }
`;

const SearchContainer = styled.div`
  ${theme('below.md')} {
    [data-sticky-header='true'] & {
      padding-top: 80px;
    }
  }
  padding: 8px;
  [data-channel='Skoterdelen'] & {
    width: 100%;
    .search-input-wrap,
    .search-input-wrap div {
      width: 100%;
      max-width: 100%;
      svg {
        left: auto;
        right: 0;
      }
    }
  }
  .inner-search-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    background: #ffffff;
    border-radius: 3px;
    padding: 0 12px;
    input {
      width: 100%;
      border: 0;
      appearance: none;
      outline: none;
      padding: 12px 0px;
      ${theme('below.md')} {
        font-size: 16px;
      }
    }

    svg {
      color: rgb(0, 181, 91);
      width: 20px;
    }
  }

  [data-channel='Eyewearstore'] & {
    background: #ffffff;
    .inner-search-wrapper {
      border: 1px solid #878787;
      padding: 0;
      input {
        padding: 12px 40px;
      }
      ${theme('below.sm')} {
        width: 100%;
      }
    }
  }

  [data-channel='Skoterdelen'] & {
    background: #000000;
    ${theme('below.sm')} {
      [data-sticky-header='true'] & {
        padding-top: 54px;
      }
    }
  }

  &.in-header {
    margin-left: 20px;
    width: 50%;
    padding: 0px;
    .inner-search-wrapper {
      padding: 0 8px;
    }
    input {
      padding: 8px;
    }
  }
`;

// Addwish search or Jetshop Search
export function Search(props) {
  return (
    <SearchContainer {...props}>
      <div className="inner-search-wrapper">
        <SearchField />
      </div>
    </SearchContainer>
  );
}

function TopHeader({ isWeAhl }) {
  return (
    <>
      <HeaderContainer>
        <MaxWidth>
          <HeaderItemsContainer>
            <div className="left-menu">
              <DrawerTrigger preventOverflow={true} id="menu-drawer">
                {(drawer) => (
                  <MenuButton
                    onClick={
                      drawer.isOpen ? drawer.hideTarget : drawer.showTarget
                    }
                  >
                    <div className="header-button">
                      <MenuIcon icon={['fal', 'bars']} />
                    </div>
                  </MenuButton>
                )}
              </DrawerTrigger>
            </div>

            <div className="center-menu">
              <Link to="/">
                {isWeAhl ? (
                  <Eyewearstore className="eyewearstore-logo" />
                ) : (
                  <img src={Logo} />
                )}
              </Link>
              <Between breakpoints={['sm', 'md']}>
                {(matches) =>
                  matches ? (
                    <Search className="in-header" isWeAhl={isWeAhl} />
                  ) : null
                }
              </Between>
            </div>

            <div className="right-menu">
              {!isWeAhl && (
                <FilterContext.Consumer>
                  {({ activeFilters }) => (
                    <ModelsButton activeFilters={activeFilters} />
                  )}
                </FilterContext.Consumer>
              )}
              <FavoritesButton />
              <CartButton />
            </div>
          </HeaderItemsContainer>
        </MaxWidth>
      </HeaderContainer>
      <Between breakpoints={['xs', 'sm']}>
        {(matches) => (matches ? <Search isWeAhl={isWeAhl} /> : null)}
      </Between>
    </>
  );
}

export default function Header({ isWeAhl }) {
  const result = useQuery(homeCategoriesQuery, {
    variables: {
      levels: 1,
    },
  });

  useScroll();

  return (
    <>
      <Container data-channel={isWeAhl ? 'Eyewearstore' : 'Skoterdelen'}>
        {isWeAhl && <WeAhlUsp />}
        <Above breakpoint="md">
          {(matches) =>
            matches ? (
              <>
                <DesktopHeader result={result.data} />
              </>
            ) : (
              <>
                <TopHeader categories={result} isWeAhl={isWeAhl} />
                <MobileMenu data={result.data} isWeAhl={isWeAhl} />
                <ModelFlyout />
                <CartFlyout />
                <FavoritesFlyout />
              </>
            )
          }
        </Above>
      </Container>
    </>
  );
}
