import { useMemo } from "react";

export const availableStatus = [
  {
    key: 0,
    value: 'Tillfälligt slut i lager',
    className: 'notBuyable',
    buyable: false,
    text: 'Denna vara är tillfälligt slut, kontakta oss för mer information.',
    icon: 4
  },
  {
    key: 20000000,
    value: 'Din order skickas omedelbart',
    className: 'buyable',
    buyable: true,
    text: 'Denna vara kan skickas omgående och finns i vårt lager.',
    icon: 1
  },
  {
    key: 14000000,
    value: 'Skickas inom 2-3 dagar',
    className: 'buyable',
    buyable: true,
    text: 'Denna vara kan skickas inom 2-3 dagar från vårt lager.',
    icon: 2
  },
  {
    key: 15000000,
    value: 'Skickas inom 3-5 dagar',
    className: 'buyable',
    buyable: true,
    text: 'Denna vara kan skickas inom 3-5 dagar från vårt lager.',
    icon: 2
  },
  {
    key: 16000000,
    value: 'Beställningsvara',
    className: 'buyable',
    buyable: true,
    text: 'Denna vara är en beställningsvara',
    icon: 3
  },
  {
    key: 17000000,
    value: 'Tillfälligt slut i lager',
    className: 'buyable',
    buyable: true,
    text: 'Denna vara är en beställningsvara och finns inte inne i lager.',
    icon: 1
  },
  {
    key: 2000000,
    value: 'I lager, skickas omgående',
    className: 'buyable',
    buyable: true,
    text: 'Denna vara kan skickas omgående och finns i vårt lager.',
    icon: 1
  },
  {
    key: 1400000,
    value: 'Skickas inom 2-3 dagar',
    className: 'buyable',
    buyable: true,
    text: 'Denna vara kan skickas inom 2-3 dagar från vårt lager.',
    icon: 2
  },
  {
    key: 1500000,
    value: 'Skickas inom 3-5 dagar',
    className: 'buyable',
    buyable: true,
    text: 'Denna vara kan skickas inom 3-5 dagar från vårt lager.',
    icon: 2
  },
  {
    key: 1600000,
    value: 'Beställningsvara',
    className: 'buyable',
    buyable: true,
    text: 'Denna vara är en beställningsvara.',
    icon: 3
  },
  {
    key: 1700000,
    value: 'Tillfälligt slut i lager',
    className: 'buyable',
    buyable: true,
    text: 'Denna vara är en beställningsvara och finns inte inne i lager.',
    icon: 1
  }
];

const defaultValue = {
  key: 1400000,
  value: 'I lager för leverans inom 2-3 dagar',
  className: 'buyable',
  buyable: true,
  text: 'Denna vara kan skickas inom 2-3 dagar från vårt lager',
  icon: 2
};

function getStockValue(product) {
  const stockValue = Math.round(parseInt(product?.stockStatus?.text) / 1000000) * 1000000
  return availableStatus.find(s => s.key === stockValue)
}

const useStocklevelCalc = ({ product }) => {


  const status = useMemo(() => {
    if (product) {
      return getStockValue(product)
    }
  }, [product.stockStatus])

  if (status) {
    return {
      productStockStatus: status,
    }
  }

  return {
    productStockStatus: defaultValue,
  }
}

export { useStocklevelCalc, getStockValue }