import React, { Component } from 'react';
import styled from 'react-emotion';
import SubscribeToStockNotifications from '@jetshop/core/components/Mutation/SubscribeToStockNotifications';
import EmailInput from '../../components/ui/EmailInput';
import theme from '@jetshop/ui/utils/theme';

const StockStatusText = styled('p')`
  margin-bottom: 0.5rem;
  text-align: center;
  background: #00bdff;
  color: #fff;
  display: block;
  width: 361px;
  max-width: 100%;
  cursor: pointer;
  padding: 5px;
  transition: 0.2s;
  &:hover {
    opacity: 0.9;
  }
  span {
    display: block;
    font-weight: 900;
  }
  span:first-child {
    font-size: 1.8rem;
  }
  span:last-child {
    font-size: 14px;
  }
`;

const StyledEmailInput = styled(EmailInput)`
  margin-bottom: 1rem;
  position: relative;
  background: white;
  width: 100%;

  + p {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    text-align: center;
    margin-top: -10px;
    color: #cd3132;
  }

  input,
  button {
    color: #999999;
    font-size: 14px;
    background-color: inherit;
    &:focus {
      outline: none;
    }
  }
  button {
    border-top: 1px solid #00bdff;
    border-bottom: 1px solid #00bdff;
    border-right: 1px solid #00bdff;
    background: #00bdff;
    cursor: pointer;
    color: white;
    width: 75px;
    :hover {
    }
  }
  input {
    border-top: 1px solid #cecece;
    border-bottom: 1px solid #cecece;
    border-left: 1px solid #cecece;
    width: 286px;
    ::placeholder {
      color: #999999;
    }
  }
  &.isDirty {
    input,
    button {
      color: black;
    }
    button {
      border-top: 1px solid black;
      border-bottom: 1px solid black;
      border-right: 1px solid black;
    }
    input {
      border-top: 1px solid black;
      border-bottom: 1px solid black;
      border-left: 1px solid black;
    }
  }
`;
const StockNotificationWrapper = styled('div')`
  margin-top: 12px;
  ${theme('below.md')} {
    max-width: 100%;
  }
  > div {
    margin-bottom: 1rem;
    position: relative;
  }
`;

export default class StockNotification extends Component {
  state = {
    completed: false,
    showForm: false
  };

  onCompleted = result => {
    this.setState({
      completed: result.subscribeToStockNotifications
    });
  };

  render() {
    const { articleNumber } = this.props;
    const { completed } = this.state;

    return (
      <StockNotificationWrapper>
        <StockStatusText
          onClick={() => this.setState({ showForm: !this.state.showForm })}
        >
          <span>Kontakta mig</span>
          <span>när denna produkt finns i lager igen</span>
        </StockStatusText>

        {this.state.showForm && (
          <SubscribeToStockNotifications onCompleted={this.onCompleted}>
            {(mutate, result) => (
              <StyledEmailInput
                completed={completed}
                error={result.error}
                loading={result.loading}
                onSubmit={email => {
                  this.setState({
                    completed: false,
                    error: false
                  });
                  mutate(email, articleNumber);
                }}
              />
            )}
          </SubscribeToStockNotifications>
        )}
      </StockNotificationWrapper>
    );
  }
}
