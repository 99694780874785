import React, { Component } from 'react';

const ModelSelectorContext = React.createContext();

export class ModelSelectorProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeFilter: [],
      modelCategory: 106,
      redirectUrl: false,
      filterOn: true
    };
  }
  componentDidMount = () => {
    this.setState({ redirectUrl: false });
    if (
      typeof window !== 'undefined' &&
      typeof window.localStorage !== 'undefined'
    ) {
      if (localStorage.getItem('activeFilter')) {
        const activeFilter = JSON.parse(localStorage.getItem('activeFilter'));
        this.setState({ activeFilter: activeFilter });
      }
      if (
        localStorage.getItem('activeCat') &&
        this.state.modelCategory !== localStorage.getItem('activeCat')
      ) {
        this.setState({
          modelCategory: parseInt(localStorage.getItem('activeCat'))
        });
      }
    }
  };
  componentDidUpdate = () => {};
  saveFilter = () => {};
  applyFilter = (id, item, index, mini = false) => {
    let currentlySelected = this.state.activeFilter;
    let values = [];
    if (!currentlySelected) {
      values.push(item);
    } else {
      values = currentlySelected.slice(0, index);
      values[index] = item;
    }

    this.setState(
      {
        activeFilter: values
      },
      () => {
        if (
          typeof window !== 'undefined' &&
          typeof window.localStorage !== 'undefined'
        ) {
          localStorage.setItem('activeFilter', JSON.stringify(values));
          if (mini && index === 2) {
            var url =
              this.state.modelCategory === 106 ? '/snoskoter' : '/cross';
            this.setState({ redirectUrl: url });
          }
        }
      }
    );
  };
  toggleFilter = status => {
    this.setState({
      filterOn: !this.state.filterOn
    });
  };
  turnOnFilter = () => {
    if (!this.state.filterOn) {
      this.setState({
        filterOn: true
      });
    }
  };
  clearFilter = () => {
    this.setState({
      activeFilter: []
    });

    if (
      typeof window !== 'undefined' &&
      typeof window.localStorage !== 'undefined'
    ) {
      localStorage.setItem('activeFilter', JSON.stringify([]));
    }
  };
  enableFilter = () => {
    if (
      typeof window !== 'undefined' &&
      typeof window.localStorage !== 'undefined'
    ) {
      if (localStorage.getItem('activeFilter')) {
        const activeFilter = JSON.parse(localStorage.getItem('activeFilter'));
        this.setState({ activeFilter: activeFilter });
      }
    }
  };
  selectCategory = id => {
    let url = '';
    this.setState({ modelCategory: id }, () => {
      if (
        typeof window !== 'undefined' &&
        typeof window.localStorage !== 'undefined'
      ) {
        localStorage.setItem('activeCat', id);
      }
    });
    if (id === 106) {
      url = '/snoskoter';
    } else if (id === 201) {
      url = '/cross';
    } else if (id === 3444) {
      url = '/atv-1';
    } else if (id === 454) {
      url = '/motorcykel';
    }
    this.setState({ redirectUrl: url });
  };
  removeRedirect = () => {
    this.setState({ redirectUrl: false });
  };
  render() {
    const { children } = this.props;
    return (
      <ModelSelectorContext.Provider
        value={{
          modelCategory: this.state.modelCategory,
          filterOn: this.state.filterOn,
          applyFilter: this.applyFilter,
          activeFilter: this.state.activeFilter,
          toggleFilter: this.toggleFilter,
          enableFilter: this.enableFilter,
          selectCategory: this.selectCategory,
          clearFilter: this.clearFilter,
          redirectUrl: this.state.redirectUrl,
          removeRedirect: this.removeRedirect,
          turnOnFilter: this.turnOnFilter
        }}
      >
        {children}
      </ModelSelectorContext.Provider>
    );
  }
}

export const ModelSelectorConsumer = ModelSelectorContext.Consumer;
