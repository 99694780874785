import t from '@jetshop/intl';
import Accordion from '@jetshop/ui/Accordion/Accordion';
import React, { Component } from 'react';
import styled from 'react-emotion';
import {
  ProductSection as UIProductSection,
  SectionHeading
} from './ProductInfo';

const Description = styled('article')`
  line-height: 25px;
`;
const AccordionWrapper = styled('div')`
  border-top: 1px solid #e8e8e8;
  margin-top: 20px;
  width: 100%;
`;

const ProductSection = styled(UIProductSection)`
  border-bottom: 1px solid #e8e8e8;
  padding: 1rem !important;
  margin: 1rem 0;
  font-size: 0.875rem;

  .hide {
    display: none;
  }

  img {
    max-width: 100%;
  }
  ${SectionHeading} {
    display: flex;
    justify-items: space-between;
    align-items: center;
    margin-bottom: 0;
    font-size: 1.2rem;
    text-transform: uppercase;
    + * > :first-child {
      margin-top: 1rem;
    }
  }
`;

const IndicatorIcon = styled('span')`
  margin-left: auto;
  font-size: 1.25rem;
`;
const AccordionIndicator = ({ isOpen }) => (
  <IndicatorIcon>{isOpen ? '-' : '+'}</IndicatorIcon>
);

export default class ProductInfoAccordion extends Component {
  render() {
    const { children, product } = this.props;
    let newChildren = [];
    children.map((child, index) => {
      if (child) {
        if (child.length) {
          child.map(childLvl2 => {
            newChildren.push(childLvl2);
            return newChildren;
          });
        } else {
          newChildren.push(child);
        }
      }
      return child;
    });
    return (
      <Accordion children={children}>
        {({ openIndexes, handleClick, AccordionContent }) => (
          <AccordionWrapper>
            <ProductSection>
              <SectionHeading onClick={() => handleClick(0)}>
                <span>{t('Product description')}</span>
                <AccordionIndicator isOpen={openIndexes.includes(0)} />
              </SectionHeading>
              <AccordionContent isOpen={openIndexes.includes(0)}>
                <Description
                  dangerouslySetInnerHTML={{
                    __html: product.description
                  }}
                />
              </AccordionContent>
            </ProductSection>

            {newChildren.map((child, index) => {
              if (child !== undefined && child.props !== undefined) {
                return (
                  <ProductSection key={index}>
                    <SectionHeading onClick={() => handleClick(index + 1)}>
                      <span>{child.props.label}</span>
                      <AccordionIndicator
                        isOpen={openIndexes.includes(index + 1)}
                      />
                    </SectionHeading>
                    <AccordionContent isOpen={openIndexes.includes(index + 1)}>
                      <Description>{child.props.children}</Description>
                    </AccordionContent>
                  </ProductSection>
                );
              } else {
                return false;
              }
            })}
          </AccordionWrapper>
        )}
      </Accordion>
    );
  }
}
