import React, { Component } from 'react';
import styled, { css } from 'react-emotion';
import MaxWidth from '../../Layout/MaxWidth';
import theme from '@jetshop/ui/utils/theme';

import PostnordLogo from '../../../img/postnord-logo.png';
import NewsletterField from '../../ui/NewsletterField';

import { Link } from 'react-router-dom';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';
import {
  faEnvelope,
  faMobileAlt,
  faComment,
} from '@fortawesome/pro-light-svg-icons';
import AuthContext from '@jetshop/core/components/AuthContext/AuthContext';
import { Below } from '@jetshop/ui/Breakpoints';
import { useVat } from '../../Price/VatContext';
import { useChannel } from '../../../hooks/useChannel';
import { StyledCheckBox } from '../Header/Desktop/DesktopHeader';
import { ReactComponent as YouTube } from '../../../svg/youtube.svg';
import { ReactComponent as TikTok } from '../../../svg/tiktok.svg';

library.add(faInstagram, faFacebook);
library.add(faEnvelope, faMobileAlt, faComment);

const Container = styled('footer')`
  padding: 3rem 0 0 0;
  background: #f8f8f8;
  ${theme('below.md')} {
    padding: 2rem 0 0 0;
  }
  + div {
    background: rgba(0, 0, 0, 0.5);
    z-index: 4;
  }
`;

const StyledMaxWidth = styled(MaxWidth)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  max-width: ${theme('general.maxWidth')};
  width: 100%;
  margin: auto;
  padding: 0;
  a {
    color: #fff;
    text-decoration: none;
  }
`;

const FooterItem = styled('div')`
  flex: 1;
  padding: 0 1rem;

  ${theme('below.md')} {
    flex: none;
    width: 100%;
    margin-bottom: 2rem;
    text-align: left;
    * {
      text-align: center;
      justify-content: center;
    }
    [data-channel='Skoterdelen'] & {
      * {
        text-align: left;
        justify-content: unset;
      }
    }
  }

  li {
    display: block;
    a,
    span {
      padding: 5px 0;
      display: flex;
      align-items: center;
      font-size: 18px;
      cursor: pointer;
      svg {
        margin-right: 20px;
        margin-left: 0;
      }
    }
  }
  ${(props) =>
    props.center
      ? css`
          text-align: center;
        `
      : css`
          text-align: left;
        `};
`;

const FooterItemLarge = styled(FooterItem)`
  flex: 2;
  + div {
    padding-left: 3rem;
    ${theme('below.md')} {
      padding-left: 1rem;
    }
  }
`;

const FooterIcon = styled(FontAwesomeIcon)`
  color: #fff;
  font-size: 32px;
  min-width: 32px;
`;

const FooterUpper = styled('div')`
  background: ${theme('colors.lightGrey')};
  color: #fff;
  padding: 5rem 0;
  ${theme('below.md')} {
    padding: 2.5rem 0;
  }
  [data-channel='Skoterdelen'] & {
    background: ${theme('colors.darkGrey')};
  }
`;

const FooterLower = styled('div')`
  background: #1b1b1a;
  color: #fff;
  padding: 5rem 0;
  ${theme('below.md')} {
    padding: 2.5rem 0;
  }
`;

const FooterItemTitle = styled('h3')`
  text-transform: uppercase;
  font-size: 28px;
  margin-bottom: 10px;
  ${theme('below.md')} {
    font-size: 1.5rem;
  }
`;

const FooterItemText = styled('p')`
  font-size: 18px;
  line-height: 1.5;
  ${(props) =>
    props.small
      ? css`
          max-width: 250px;
        `
      : css`
          max-width: 100%;
        `};

  ${theme('below.md')} {
    font-size: 1rem;
  }
  p {
    margin-top: 1rem;
  }
`;

const SocialIcons = styled('ul')`
  li {
    display: inline-block;
    a {
      padding: 5px 10px;
      text-align: center;
      svg {
        margin: auto;
        font-size: 3rem;
        height: 45px;
        width: 40px;
      }
    }
  }
`;

const Payment = styled('div')`
  text-align: center;
  img  {
    margin-bottom: 20px;
  }
`;

const WeAhlLower = styled.div`
  background: ${theme('colors.eyeWearYellow')};
  margin-top: 4.5rem;
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 1rem 0;
    &.vertical {
      flex-direction: row;
      justify-content: space-evenly;
      margin: 0 auto;
      padding: 0.5rem 0;
      max-width: 75rem;
      li {
        padding: 0.5rem;
      }
    }
    li {
      padding: 1.1rem;
      font-size: 1.4rem;
      a {
        color: ${theme('colors.lightGrey')};
      }
    }
  }
`;

const weAhlFooterLinks = {
  '/kundtjanst-3': 'Kundtjänst',
  '/kopvillkor-3': 'Köpvillkor',
  '/om-oss-1': 'Om oss',
};

function DefaultFooter() {
  const [incVat, setIncVat] = useVat();
  return (
    <Container data-channel="Skoterdelen">
      <FooterUpper>
        <StyledMaxWidth>
          <FooterItem>
            <FooterItemTitle>Nyhetsbrev</FooterItemTitle>
            <FooterItemText small>
              Prenumerera på vårt nyhetsbrev för att få nyheter och erbjudanden!
            </FooterItemText>
            <NewsletterField />
            <AuthContext.Consumer>
              {({ loggedIn }) => (
                <Link style={{ display: 'block', margin: '1em 0' }} to="/login">
                  {loggedIn ? 'Mina sidor' : 'Logga in'}
                </Link>
              )}
            </AuthContext.Consumer>
            <Below breakpoint="md">
              <StyledCheckBox
                label={
                  incVat ? 'Visar priser inkl. moms' : 'Visar priser exkl. moms'
                }
                onChange={setIncVat}
                checked={incVat}
              />
            </Below>
          </FooterItem>

          <FooterItem center>
            <FooterItemTitle>Följ oss</FooterItemTitle>
            <SocialIcons>
              <li>
                <a
                  href="https://www.facebook.com/pages/Skoterdelen/167390259942643"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FooterIcon icon={['fab', 'facebook']} />
                </a>
              </li>
              <li>
                <a
                  href="https://instagram.com/skoterdelen"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FooterIcon icon={['fab', 'instagram']} />
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UCuApKpX583aNATtClR71-zA"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <YouTube />
                </a>
              </li>
              <li>
                <a
                  href="https://www.tiktok.com/@skoterdelen"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <TikTok />
                </a>
              </li>
            </SocialIcons>
          </FooterItem>

          <FooterItem>
            <FooterItemTitle>Kontakta kundtjänst</FooterItemTitle>
            <ul>
              <li>
                <a href="tel:0687581030">
                  <FooterIcon icon={['fal', 'mobile-alt']} /> 0687-58 10 30
                </a>
              </li>
              <li>
                <a href="mailto:support@skoterdelen.com">
                  <FooterIcon icon={['fal', 'envelope']} />{' '}
                  support@skoterdelen.com
                </a>
              </li>
              <li>
                <span
                  onClick={() => {
                    window.psbRHlow();
                    return false;
                  }}
                >
                  <FooterIcon icon={['fal', 'comment']} /> Börja chatta
                </span>
              </li>
              <li>
                <FooterItemText>
                  10-16 Helgfria vardagar. Lunch 12-13
                  <br />
                  Vid frågor om order, ha ditt ordernummer tillgängligt.
                </FooterItemText>
              </li>
            </ul>
          </FooterItem>
        </StyledMaxWidth>
      </FooterUpper>
      <FooterLower>
        <StyledMaxWidth>
          <FooterItemLarge>
            <FooterItemText>
              Vi har ett komplett sortiment av skoterdelar och skoterkläder till
              garanterat lägsta pris! Vi utökar varje dag vårt sortiment med
              delar till moped, snöskoter, skoter, gräsklippare, cross och ATV.
              Vi har sålt reservdelar till snöskoter sedan 2004 och varje dag
              lämnar 100-tals leveranser vårt lager. Är du i behov av mopeddelar
              eller reservdelar till din ATV hittar du det i vår webbutik.
              Skoterdelen säljer idag till både privatpersoner/konsument och
              återförsäljare/företag, främst är det reservdelar och personlig
              utrustning till snöskoter och skoter, gräsklippare, moped, ATV och
              cross/enduro. Välkommen till Skoterdelen.com!
            </FooterItemText>
          </FooterItemLarge>
          <FooterItem>
            <Payment>
              <img
                src="https://cdn.klarna.com/1.0/shared/image/generic/badge/sv_se/checkout/short-white.png?width=300&tool=widget"
                alt="Betalningsalternativ"
              />
              <img
                style={{ display: 'block', margin: '0 auto' }}
                width="150"
                src={PostnordLogo}
                alt="Postnord"
              />
            </Payment>
          </FooterItem>
        </StyledMaxWidth>
      </FooterLower>
    </Container>
  );
}

function WeAhlFooter() {
  return (
    <Container>
      <FooterUpper>
        <StyledMaxWidth>
          <FooterItem>
            <Payment>
              <img
                src="https://cdn.klarna.com/1.0/shared/image/generic/badge/sv_se/checkout/short-white.png?width=300&tool=widget"
                alt="Betalningsalternativ"
              />
              <img
                style={{ display: 'block', margin: '0 auto' }}
                width="150"
                src={PostnordLogo}
                alt="Postnord"
              />
            </Payment>
          </FooterItem>
          <FooterItem center>
            <FooterItemTitle>Följ oss</FooterItemTitle>
            <SocialIcons>
              <li>
                <a
                  href="https://www.facebook.com/eyewearstore.se/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FooterIcon icon={['fab', 'facebook']} />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/eyewearstore_"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FooterIcon icon={['fab', 'instagram']} />
                </a>
              </li>
            </SocialIcons>
          </FooterItem>
          <FooterItem>
            <FooterItemTitle>Kontakta kundtjänst</FooterItemTitle>
            <ul>
              <li>
                <a href="tel:0687581030">
                  <FooterIcon icon={['fal', 'mobile-alt']} /> 0687-58 10 30
                </a>
              </li>
              <li>
                <a href="mailto:support@eyewearstore.se">
                  <FooterIcon icon={['fal', 'envelope']} />{' '}
                  support@eyewearstore.se
                </a>
              </li>
              <li>
                <FooterItemText>
                  10-16 Helgfria vardagar. Lunch 12-13
                  <br />
                  Vid frågor om order, ha ditt ordernummer tillgängligt.
                </FooterItemText>
              </li>
            </ul>
          </FooterItem>
        </StyledMaxWidth>
        <WeAhlLower>
          <Below breakpoint="md">
            {(matches) =>
              matches ? (
                <StyledMaxWidth>
                  <WeAhlFooterLinks />
                </StyledMaxWidth>
              ) : (
                <WeAhlFooterLinks className="vertical" />
              )
            }
          </Below>
          <FooterItemLarge
            style={{
              background: 'white',
              marginBottom: '-3rem',
              padding: '2.2rem 7rem',
            }}
          >
            <div
              style={{ color: 'black', maxWidth: '35rem', margin: '0 auto' }}
            >
              <p>
                Vi har ett stort och noga utvalt sortiment av solglasögon som
                passar alla stilar, från klassiska bågar med mörka glas till
                moderna frameless glasögon med spegelglas i olika färger.
                Självklart alltid till rätt pris och med snabba leveranser så
                att du kan toppa din outfit när det passar dig! I vår webbutik
                hittar du allt från spännande och prisvärda alternativ från
                märken som Daytona, We Ahl och CHPO till klassiska
                prestigemärken som Oakley och Red Bull.
              </p>
              <p style={{ marginTop: '1rem' }}>
                Välkommen till oss på Eyewearstore!
              </p>
            </div>
          </FooterItemLarge>
        </WeAhlLower>
      </FooterUpper>
    </Container>
  );
}

function WeAhlFooterLinks(props) {
  return (
    <ul {...props}>
      {Object.keys(weAhlFooterLinks).map((link) => (
        <li key={link}>
          <Link to={link}>{weAhlFooterLinks[link]}</Link>
        </li>
      ))}
    </ul>
  );
}

export default function Footer() {
  const { isWeAhl } = useChannel();
  return isWeAhl ? <WeAhlFooter /> : <DefaultFooter />;
}
