import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';

export const VatContext = createContext();

const getItem = (name) => typeof window === undefined ? true : JSON.parse(window.localStorage.getItem(name))

// This "has" to be set in localStorage. 
// We're resetting state on AddWish search results click,
// since they aren't using a browser router.
// An alternative would be to mimic Link with history.push on each Addwish result item.
// I'd rather have this logic elsewhere (like in the AddWish template, provided by AddWish).
export const VatProvider = ({ children }) => {
  const [incVat, setIncVat] = useState(true);

  useEffect(() => {
    try {
      const vat = getItem('includeVat')
      if (vat !== null) {
        setIncVat(vat)
      }
    } catch {
      setIncVat(true)
    }
  }, [])

  const setvat = useCallback(() => setIncVat(vat => !vat), [incVat])

  useEffect(() => {
    window.localStorage.setItem('includeVat', incVat)
  }, [incVat])

  return (
    <VatContext.Provider
      value={{
        incVat: incVat,
        setIncVat: setvat
      }}
    >
      {children}
    </VatContext.Provider>
  );
};

export function useVat() {
  const vat = useContext(VatContext)
  if (!vat) {
    throw Error('Vatcontext cannot be used outside Vatprovider.')
  }

  const { incVat, setIncVat } = vat

  return [incVat, setIncVat]
}
