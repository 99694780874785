import React, { Component } from 'react';
import styled from 'react-emotion';

import Image from '@jetshop/ui/Image';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
  faAngleLeft,
  faAngleRight,
} from '@fortawesome/pro-light-svg-icons';
library.add(faTimes, faAngleLeft, faAngleRight);

const MobileSliderWrapper = styled('div')`
  position: relative;
  margin-bottom: 10px;
  iframe {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 57;
    height: 70vh;
    width: 70vw;
  }
`;

const ProductImage = styled(Image)`
  max-width: 100%;
  background-color: white;
`;

const Arrow = styled(FontAwesomeIcon)`
  display: block;
  color: black;
  font-size: 2rem;
  z-index: 58;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  width: 50px !important;
  height: 50px !important;
  &.right {
    left: auto;
    right: 0;
  }
`;

const Dots = styled('div')`
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
`;

const Dot = styled('span')`
  height: 10px;
  width: 10px;
  background: black;
  opacity: 0.3;
  margin: 0 5px;
  border-radius: 100%;
  &.active {
    opacity: 1;
  }
`;

const VideoWrapper = styled('div')`
  position: relative;
  padding-bottom: 100%;
  height: 0;
  iframe {
    position: absolute;
    transform: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export default class MobileSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      activeIndex: 0,
    };
  }
  componentDidMount() {
    this.setState({
      isOpen: this.props.isOpen,
      index: false,
    });
    this.setState({ index: this.props.activeIndex });
  }

  render() {
    const { images, mobileSliderVideo } = this.props;
    let imageArray = [...images];
    if (mobileSliderVideo) {
      imageArray.push({ video: true, url: mobileSliderVideo });
    }
    const fullImage = imageArray[this.state.index];

    return (
      <MobileSliderWrapper>
        <React.Fragment>
          <Arrow
            icon={['fal', 'angle-left']}
            onClick={() => {
              if (this.state.index - 1 < 0) {
                this.setState({ index: imageArray.length - 1 });
              } else {
                this.setState({ index: this.state.index - 1 });
              }
            }}
          >
            {' '}
            &lt;{' '}
          </Arrow>
          <Arrow
            icon={['fal', 'angle-right']}
            onClick={() => {
              if (this.state.index + 1 > imageArray.length - 1) {
                this.setState({ index: 0 });
              } else {
                this.setState({ index: this.state.index + 1 });
              }
            }}
            className="right"
          >
            {' '}
            &gt;{' '}
          </Arrow>
          {fullImage && (
            <>
              {fullImage.video ? (
                <VideoWrapper>
                  <iframe
                    title="Video"
                    width="560"
                    height="315"
                    src={mobileSliderVideo}
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  />
                </VideoWrapper>
              ) : (
                <ProductImage
                  aspect={'1:1'}
                  sizes={'600'}
                  src={fullImage.url}
                  alt={fullImage.alt}
                />
              )}
            </>
          )}
          <Dots>
            {imageArray.map((image, i) => {
              return (
                <React.Fragment key={i}>
                  {this.state.index === i ? (
                    <Dot key={i} className="active" />
                  ) : (
                    <Dot key={i} />
                  )}
                </React.Fragment>
              );
            })}
          </Dots>
        </React.Fragment>
      </MobileSliderWrapper>
    );
  }
}
