import React, { Component, Fragment } from 'react';
import styled from 'react-emotion';

import { Link } from 'react-router-dom';

import Analytics from '@jetshop/core/analytics/Analytics';
import { trackList } from '@jetshop/core/analytics/tracking';
import theme from '@jetshop/ui/utils/theme';

import ModelPickerImg from '../../img/skoterval.jpg';

import GridProduct from '../CategoryPage/GridProduct';
import { Above } from '@jetshop/ui/Breakpoints';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';

const ProductGridContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;

  &.addwish-grid {
    > div {
      &:first-child {
        padding-left: 0;
      }
      ${theme('below.md')} {
        padding-left: 3px;
        padding-right: 3px;
      }
    }
  }

  ${theme('below.md')} {
    padding: 5px 0 0 0;
    justify-content: center;
  }

  > div {
    width: 25%;
    position: relative;
    ${theme('below.md')} {
      width: 50%;
    }
  }
  &.has-push > div {
    width: 20%;
    padding: 0 10px;
    margin: 0;
    ${theme('below.md')} {
      padding: 5px;
    }

    a {
      margin: 0;
    }

    ${theme('below.md')} {
      width: 50%;
      &:last-child {
        display: none;
      }
    }
  .product-image:after {
    content: '';
    background: transparent;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: 5px solid white;
  }
  }
`;

const PushContainer = styled('div')`
  display: flex;
  flex-direction: column;
  a {
    flex: 1;
    text-decoration: none;
    color: inherit;
    display: block;
    background-color: white;
    padding: 5px;
    overflow: hidden;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    ${theme('below.md')} {
      margin: 0 5px 10px 5px;
    }
    h3 {
      font-size: 2rem;
      color: #fff;
      text-transform: uppercase;
      margin-bottom: 35px;
      margin-top: auto;
      flex: 1;
      transform: rotate(-7deg);
      ${theme('below.md')} {
        font-size: 1.3rem;
      }
      span {
        display: block;
        background: #000;
        margin-bottom: 0.5em;
        padding: 5px;
        &:first-child {
          width: 115%;
          margin-left: -15px;
          ${theme('below.md')} {
            margin-left: -12px;
          }
        }
        &:last-child {
          width: 120%;
          margin-left: -25px;
          ${theme('below.md')} {
            margin-left: -17px;
          }
        }
      }
    }
  }
`;
const PushBackground = styled('div')`
  background: url(${ModelPickerImg}) no-repeat center;
  background-size: cover;
  display: flex;
  flex: 1;
  overflow: hidden;
`;

class GridPush extends Component {
  render() {
    return (
      <Above breakpoint="md">
        {(matches) =>
          matches ? (
            <PushContainer>
              <Link to="/snoskoter">
                <PushBackground>
                  <h3>
                    <span>Välj din</span> <span>skotermodell</span>
                  </h3>
                </PushBackground>
              </Link>
            </PushContainer>
          ) : (
            <PushContainer>
              <DrawerTrigger preventOverflow={true} id="model-drawer">
                {(drawer) => (
                  <a
                    href
                    onClick={
                      drawer.isOpen ? drawer.hideTarget : drawer.showTarget
                    }
                  >
                    <PushBackground>
                      <h3>
                        <span>Välj din</span> <span>skotermodell</span>
                      </h3>
                    </PushBackground>
                  </a>
                )}
              </DrawerTrigger>
            </PushContainer>
          )
        }
      </Above>
    );
  }
}

const productPlaceholder = {
  name: '',
  images: [],
};
const ProductGrid = ({
  className = 'test',
  products,
  loading,
  listName,
  categoryPath = null,
  ProductComponent = GridProduct,
  push,
  addWish,
  style,
  hash = null,
}) => {
  const productList =
    products.length === 0 && loading
      ? Array(4).fill(productPlaceholder)
      : products;

  return (
    <ProductGridContainer
      data-testid="product-grid"
      className={push ? 'has-push ' + className : className}
    >
      {push && <GridPush />}
      {products && (
        <Fragment>
          {!loading ? (
            <Analytics
              track={trackList({
                listName,
                products: products,
              })}
            />
          ) : null}
          {productList.map((product, index) => (
            <ProductComponent
              className="product"
              loading={loading}
              key={index}
              product={product}
              addWish={addWish}
              hash={hash}
              style={style}
            />
          ))}
        </Fragment>
      )}
    </ProductGridContainer>
  );
};

export default ProductGrid;
