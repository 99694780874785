import t from '@jetshop/intl';
import { Above, Below } from '@jetshop/ui/Breakpoints';
import ListFilterFlyouts from '@jetshop/ui/Filter/FilterTypes/List/ListFilterFlyouts';
import MultiListFlyouts from '@jetshop/ui/Filter/FilterTypes/Multi/MultiListFlyouts';
import RangeFilterFlyouts from '@jetshop/ui/Filter/FilterTypes/Range/RangeFilterFlyouts';
import Drawer, { DrawerTarget, DrawerTrigger } from '@jetshop/ui/Modal/Drawer';
import SortOrderDropdown from '@jetshop/ui/SortOrder/SortOrderDropdown';
import { ReactComponent as Carrot } from '@jetshop/ui/svg/Carrot.svg';
import theme from '@jetshop/ui/utils/theme';
import get from 'lodash.get';
import React, { Component, Fragment } from 'react';
import styled from 'react-emotion';

import MaxWidth from '../../Layout/MaxWidth';
import FilterDrawer from './FilterDrawer';

import AtvBg from '../../../img/atv-kat.jpg';
import CrossBg from '../../../img/cross-kat.jpg';
import HeaderBg from '../../../img/header-background.jpg';
import MotorcycleBg from '../../../img/motorcycle-background.jpg';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
library.add(faTimes);

//import MultiListFlyouts from '../../ModelSelector/CategoryPageFilter';

const StyledMultiList = styled('section')`
  width: 100%;
  padding: 1.5rem 1rem 0.9rem 1rem;
  background-image: ${(props) => `url(${props.backgroundImage})`};
  background-color: ${(props) => `${props.backgroundColor}`};
  background-position: ${(props) => `${props.backgroundPosition}`};
  background-repeat: no-repeat;
  background-size: cover;
  .scrolled & {
    padding: 0.5rem;
  }
  .grid {
    display: grid;
    grid-auto-flow: column;
    margin-bottom: 0.5rem;
    gap: 8px 8px;
    align-items: center;
    grid-template-columns: auto auto auto auto 0;
    padding-right: 29px;
    > div {
      margin-bottom: 0;
      button {
        text-align: left;
      }
    }
    button:not(.clear-filters) {
      height: 40px;
    }
  }
`;
const NarrowMaxWidth = styled(MaxWidth)`
  max-width: 70em;
`;

const FilterToggleButton = styled('button')`
  background: #d0302c;
  color: white;
  margin-bottom: 0;
  ${theme('below.md')} {
    width: 100%;
    padding: 4px;
  }
  &.active {
    background: #00b55b;
  }
  &:focus {
    outline: none;
  }
  span {
    display: block;

    &:first-child {
      text-transform: uppercase;
      font-size: 24px;
    }
    &:last-child {
      font-size: 11px;
    }
  }
`;

const FilterTitle = styled('div')`
  text-align: center;
  display: block;
  h1 {
    text-transform: uppercase;
    font-size: 2.5rem;
    color: white;
    margin-bottom: 5px;
    margin-top: 0;
  }
  h2 {
    text-transform: uppercase;
    font-size: 1.2rem;
    color: white;
    margin-bottom: 2rem;
  }
`;

const ButtonWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  margin-bottom: ${theme('space.3')};
  button:first-of-type {
    margin-right: ${theme('space.1')};
  }
  ${theme('above.md')} {
    padding: ${theme('space.1')};
  }
  svg {
    transform: rotate(-90deg);
  }
`;

const MobileButton = styled('button')`
  height: 40px;
  background: #ffffff;
  border: 1px solid #cccccc;
  width: 50%;
  cursor: pointer;
  text-align: left;
  padding: ${theme('space.1')};
  font-weight: ${theme('fontWeights.semibold')};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Filters = styled('div')`
  width: 100%;
  display: grid;
  grid-auto-columns: repeat(4, 1fr);
  grid-auto-flow: column;
  grid-gap: 8px;
  margin-bottom: 0.5rem;

  button {
    text-transform: uppercase;
  }

  &.hide {
    display: none;
  }
`;

const FilterHolder = styled('div')`
  &.scrolled {
    position: fixed;
    z-index: 9999;
    width: 100%;
    top: 115px;
    > div {
      padding: 5px 0 0;
    }
  }
`;

export const ClearButton = styled('button')`
  border-radius: 100%;
  background: #fff;
  width: 30px;
  height: 30px;
  font-size: 1rem;
  line-height: 30px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  ${theme('below.md')} {
    margin-top: 10px;
  }
`;

const StyledPlaceholder = styled('span')`
  width: 100%;
  position: relative;
  background-color: #ffffff;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  font-weight: 700;
  padding-left: 16px;
  padding-right: 16px;
  opacity: 0.8;
`;

const FilterPlaceHolder = (props) => (
  <React.Fragment>
    {props.values.map((value, index) => (
      <StyledPlaceholder key={`ph-${index}`}>{value}</StyledPlaceholder>
    ))}
  </React.Fragment>
);

export class FilterToggler extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: false,
    };
  }
  componentDidMount() {
    if (
      typeof window !== 'undefined' &&
      typeof window.localStorage !== 'undefined'
    ) {
      if (localStorage.getItem('savedFilter')) {
        if (
          JSON.parse(localStorage.getItem('savedFilter'))[0].values.length === 3
        ) {
          if (this.props.filters) {
            var filterId = this.props.filters.filter(
              (f) => f.__typename === 'MultiListFilter'
            )[0].id;

            if (
              filterId.indexOf(
                JSON.parse(localStorage.getItem('savedFilter'))[0].id
              ) > -1
            ) {
              if (
                this.props.activeFilters.multiListFilters &&
                this.props.activeFilters.multiListFilters.length > 0 &&
                this.props.activeFilters.multiListFilters[0].values.length === 3
              ) {
                this.setState({ filter: localStorage.getItem('savedFilter') });
              }
            }
          }
        }
      }
      var filterId = this.props.filters.filter(
        (f) => f.__typename === 'MultiListFilter'
      )[0].id;

      if (
        this.props.activeFilters &&
        this.props.activeFilters.multiListFilters &&
        this.props.activeFilters.multiListFilters.length > 0 &&
        this.props.activeFilters.multiListFilters.filter(
          (f) => filterId.indexOf(f.id) > -1
        )[0] &&
        this.props.activeFilters.multiListFilters.filter(
          (f) => filterId.indexOf(f.id) > -1
        )[0].values &&
        this.props.activeFilters.multiListFilters.filter(
          (f) => filterId.indexOf(f.id) > -1
        )[0].values.length === 3
      ) {
        if (!this.state.filter) {
          this.setState({
            filter: JSON.stringify(this.props.activeFilters.multiListFilters),
          });
        }
      } else {
        if (this.state.filter) {
          this.setState({ filter: false });
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.activeFilters !== prevProps.activeFilters) {
      var filterId = this.props.filters.filter(
        (f) => f.__typename === 'MultiListFilter'
      )[0].id;

      if (
        this.props.activeFilters &&
        this.props.activeFilters.multiListFilters &&
        this.props.activeFilters.multiListFilters.length > 0 &&
        this.props.activeFilters.multiListFilters.filter(
          (f) => filterId.indexOf(f.id) > -1
        )[0] &&
        this.props.activeFilters.multiListFilters.filter(
          (f) => filterId.indexOf(f.id) > -1
        )[0].values &&
        this.props.activeFilters.multiListFilters.filter(
          (f) => filterId.indexOf(f.id) > -1
        )[0].values.length === 3
      ) {
        if (!this.state.filter) {
          this.setState({
            filter: JSON.stringify(this.props.activeFilters.multiListFilters),
          });
        }
      } else {
        if (this.state.filter) {
          this.setState({ filter: false });
        }
      }
    }
    if (this.props.flyout) {
      if (
        this.props.activeFilters &&
        this.props.activeFilters.multiListFilters &&
        this.props.activeFilters.multiListFilters.length === 0
      ) {
        if (this.state.filter) {
          this.setState({ filter: false });
        }
      }
    }
  }
  toggler = () => {
    this.props.toggleFilter();
    this.setState(
      {
        filter: this.state.filter ? false : localStorage.getItem('savedFilter'),
      },
      () => {
        if (this.state.filter) {
          this.props.applyFilters({
            multiListFilters: JSON.parse(this.state.filter),
          });
        } else {
          this.props.clearFilters();
        }
      }
    );
  };
  render() {
    const { activeFilters, filters } = this.props;
    var active = false;
    if (
      activeFilters.multiListFilters &&
      activeFilters.multiListFilters.length > 0 &&
      activeFilters.multiListFilters[0].values.length === 3
    ) {
      var multiListFilter = filters.filter((f) => {
        if (f.__typename === 'MultiListFilter') return true;
      });

      if (
        multiListFilter[0].id.indexOf(activeFilters.multiListFilters[0].id) > -1
      ) {
        active = true;
      }
    }
    return (
      <FilterToggleButton onClick={this.toggler} className={active && 'active'}>
        {active ? (
          <React.Fragment>
            <span>Filter på</span>
            <span>(endast produkter till din modell visas)</span>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <span>Filter av</span>
            <span>(alla produkter i kategorin visas)</span>
          </React.Fragment>
        )}
      </FilterToggleButton>
    );
  }
}

class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isScrolled: false,
      filterLoading: false,
      ssrDone: false,
    };
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = (event) => {
    let scrollTop = window.scrollY;
    if (scrollTop > 105) {
      this.setState({
        isScrolled: true,
      });
    } else {
      this.setState({
        isScrolled: false,
      });
    }
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    this.setState({ ssrDone: true });
  }

  render() {
    const {
      backgroundImageUrl,
      backgroundColor,
      backgroundPosition,
      filters,
      applyFilters,
      clearFilters,
      activeFilters,
      toggleFilter,
    } = this.props;
    const sortOrders = get(this, 'props.sortOrders', []);
    var hasMultiList = filters.find(function (filter) {
      return filter.__typename === 'MultiListFilter';
    });
    var filter = filters.filter((f) => f.__typename === 'MultiListFilter');
    var savedFilter =
      this.state.ssrDone && JSON.parse(localStorage.getItem('savedFilter'));

    if (
      savedFilter &&
      savedFilter.length > 0 &&
      savedFilter[0].values.length === 3 &&
      filter.length > 0 &&
      filter[0].id.indexOf(savedFilter[0].id) > -1 &&
      activeFilters.multiListFilters.length === 0
    ) {
      var placeHolder = savedFilter[0].values;
    }

    const getBackgroundImage = () => {
      if (!this.state.ssrDone) {
        return;
      }
      switch (true) {
        case window.location.href.includes('motorcykel'):
          return MotorcycleBg;
        case window.location.href.includes('snoskoter'):
          return HeaderBg;
        case window.location.href.includes('atv-1'):
          return AtvBg;
        case window.location.href.includes('cross'):
          return CrossBg;
        default:
          return '';
      }
    };

    const backgroundImage = backgroundImageUrl
      ? backgroundImageUrl
      : getBackgroundImage();

    return (
      <Fragment>
        <Above
          breakpoint="md"
          render={() => (
            <Fragment>
              <Filters className="hide">
                <ListFilterFlyouts filters={filters} />
                <RangeFilterFlyouts filters={filters} />
                {sortOrders.length > 0 && (
                  <SortOrderDropdown sortOrders={sortOrders} />
                )}
              </Filters>

              {hasMultiList && (
                <Filters>
                  <FilterHolder
                    className={this.state.isScrolled ? 'scrolled' : ''}
                  >
                    <StyledMultiList
                      backgroundImage={backgroundImage}
                      backgroundColor={backgroundColor ? backgroundColor : ''}
                      backgroundPosition={
                        backgroundPosition ? backgroundPosition : 'center'
                      }
                    >
                      {!this.state.isScrolled && (
                        <FilterTitle>
                          <h1>Välj din Maskin</h1>
                          <h2>Hitta rätt delar till din Maskin</h2>
                        </FilterTitle>
                      )}
                      <NarrowMaxWidth>
                        <div className="grid">
                          {placeHolder ? (
                            <FilterPlaceHolder values={placeHolder} />
                          ) : (
                            <MultiListFlyouts
                              filters={filters}
                              result={this.props.result}
                            />
                          )}
                          <FilterToggler
                            clearFilters={clearFilters}
                            activeFilters={activeFilters}
                            applyFilters={applyFilters}
                            toggleFilter={toggleFilter}
                            filters={filters}
                          />
                          <ClearButton
                            onClick={() => {
                              clearFilters();
                              if (
                                typeof window !== 'undefined' &&
                                typeof window.localStorage !== 'undefined'
                              ) {
                                localStorage.removeItem('savedFilter');
                              }
                            }}
                            className="clear-filters"
                          >
                            <FontAwesomeIcon icon={['fal', 'times']} />
                          </ClearButton>
                        </div>
                      </NarrowMaxWidth>
                    </StyledMultiList>
                  </FilterHolder>
                </Filters>
              )}
            </Fragment>
          )}
        />
        <Below
          breakpoint="md"
          render={() => {
            return (
              <Fragment>
                <ButtonWrapper>
                  {/* <DrawerTrigger id="filterDrawer">
                    {({ showTarget }) => (
                      <MobileButton onClick={showTarget}>
                        <p>{t('Filter')}</p>
                        <Carrot />
                      </MobileButton>
                    )}
                  </DrawerTrigger> */}

                  {sortOrders.length > 0 && (
                    <DrawerTrigger id="sortDrawer">
                      {({ showTarget }) => (
                        <MobileButton onClick={showTarget}>
                          <p>{t('Sort By')}</p>
                          <Carrot />
                        </MobileButton>
                      )}
                    </DrawerTrigger>
                  )}
                </ButtonWrapper>

                {/* <DrawerTarget id="filterDrawer">
                  {({ hideTarget, isOpen }) => (
                    <Drawer isOpen={isOpen} right>
                      <FilterDrawer close={hideTarget} filters={filters} />
                      <Filters className="hide">
                        <ListFilterFlyouts filters={filters} />
                        <RangeFilterFlyouts filters={filters} />
                        {sortOrders.length > 0 && (
                          <SortOrderDropdown sortOrders={sortOrders} />
                        )}
                      </Filters>

                      <Filters>
                        <FilterHolder
                          className={this.state.isScrolled ? 'scrolled' : ''}
                        >
                          <MultiListFlyouts
                            filters={filters}
                            result={this.props.result}
                          />
                        </FilterHolder>
                        <BoolFilters filters={filters} />
                      </Filters>
                    </Drawer>
                  )}
                </DrawerTarget> */}

                {sortOrders.length > 0 && (
                  <DrawerTarget id="sortDrawer">
                    {({ hideTarget, isOpen }) => (
                      <Drawer isOpen={isOpen} right>
                        <FilterDrawer
                          close={hideTarget}
                          sortOrders={sortOrders}
                        />
                      </Drawer>
                    )}
                  </DrawerTarget>
                )}
              </Fragment>
            );
          }}
        />
      </Fragment>
    );
  }
}

export default Filter;
