import { useQuery } from '@apollo/react-hooks';
import React from 'react';
import AddwishProductQuery from '../StartPage/AddwishProductQuery.gql';
import ProductGrid from '../StartPage/ProductGrid';

function AddWishProducts({ items, loading: loadingAddwish, className, as, ...rest }) {

  const articleNumbers = items?.map(p => p.productNumber) ?? []

  const { data } = useQuery(AddwishProductQuery, { variables: { articleNumbers }, skip: items?.length === 0 })

  return (
    <ProductGrid
      products={data?.products ?? []}
      className={`addwish-grid ${className}`}
      addWish
      {...rest}
    />
  )
}

export { AddWishProducts };
