import React, { Component } from 'react';
import styled, { css } from 'react-emotion';
import t, { Intl } from '@jetshop/intl';
import theme from '@jetshop/ui/utils/theme';
import Spinner from '../ui/Spinner';
const EmailStatusWrapper = styled('div')`
  margin-bottom: 4rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
const EmailInputWrapper = styled('form')`
  margin-bottom: 0.5rem;
  display: flex;
  input,
  button {
    height: 40px;
    color: white;
    font-size: 14px;
    background-color: inherit;
  }
  button {
    border-top: 1px solid #ffffff;
    border-bottom: 1px solid #ffffff;
    border-right: 1px solid #ffffff;
    padding-right: 20px;
    padding-left: 20px;
    cursor: pointer;
    background-color: inherit;
    transition: all 0.1s ease-in;
    :hover {
      font-weight: 600;
      background-color: #777777;
      transition: all 0.1s ease-out;
    }
  }
  input {
    border-top: 1px solid #ffffff;
    border-bottom: 1px solid #ffffff;
    border-left: 1px solid #ffffff;
    border-right: none;
    line-height: 24px;
    width: 240px;
    padding-left: 20px;
    ${theme('below.sm')} {
      width: 100%;
    }
    ::placeholder {
      color: white;
    }
    &:focus {
      ::placeholder {
        color: #777777;
      }
    }
  }
  ${theme('below.sm')} {
    width: 100%;
  }
`;

export default class EmailInput extends Component {
  state = {
    email: ''
  };
  static defaultProps = {
    onSubmit: () => { }
  };

  onEmailInputChange = event => {
    this.setState({ email: event.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    const { onSubmit } = this.props;
    onSubmit(this.state.email);
  };

  render() {
    const { className = '', loading, error, completed } = this.props;
    return (
      <EmailStatusWrapper>
        {loading ? (
          <Spinner
            className={css`
              width: 35px;
              height: 35px;
              padding: 5px;
            `}
          />
        ) : (
            <EmailInputWrapper
              className={`${className} ${
                this.state.email.length > 0 ? 'isDirty' : ''
                }`}
              onSubmit={this.onSubmit}
            >
              <Intl>
                {t => (
                  <input
                    onChange={this.onEmailInputChange}
                    placeholder={t('E-mail address')}
                    type="text"
                  />
                )}
              </Intl>
              <button>{t('OK')}</button>
            </EmailInputWrapper>
          )}
        {completed && <p>{t('Du är nu registrerad.')}</p>}
        {error && (
          <p>
            {t('Something went wrong. Please check your email and try again.')}
          </p>
        )}
      </EmailStatusWrapper>
    );
  }
}
