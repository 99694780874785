import React from 'react';
import { css } from 'emotion';
import { ProductSection } from './ProductInfo';
import { LoadingLine } from '@jetshop/ui/Loading/LoadingLine';
import styled from 'react-emotion';

const MockImage = styled('div')`
  padding-top: 100%;
  background: #f6f6f6;
`;

const MockThumbnails = styled('div')`
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
`;
const MockThumbnail = styled('div')`
  width: 22%;
`;

export default function ProductPageLoadingState() {
  return (
    <div
      className={css`
        margin-top: 2.75rem;
      `}
    >
      <ProductSection>
        <MockImage />
        <MockThumbnails>
          <MockThumbnail>
            <MockImage />
          </MockThumbnail>
          <MockThumbnail>
            <MockImage />
          </MockThumbnail>
          <MockThumbnail>
            <MockImage />
          </MockThumbnail>
          <MockThumbnail>
            <MockImage />
          </MockThumbnail>
        </MockThumbnails>
      </ProductSection>
      <ProductSection>
        <LoadingLine
          heightPx={24}
          widthRem={15}
          className={css`
            margin-bottom: 12px;
          `}
        />
        <LoadingLine
          heightPx={16}
          className={css`
            margin-bottom: 14px;
          `}
        />
        <LoadingLine
          heightPx={12}
          widthRem={10}
          className={css`
            margin-bottom: 31px;
          `}
        />
        <LoadingLine
          heightPx={12}
          widthRem={14}
          count={2}
          className={css`
            margin-bottom: 8px;
          `}
        />
        <LoadingLine
          heightPx={24}
          className={css`
            margin-top: 16px;
            margin-bottom: 14px;
          `}
        />

        <LoadingLine
          heightPx={54}
          widthRem={50}
          className={css`
            margin-top: 16px;
            margin-bottom: 14px;
          `}
        />
      </ProductSection>
    </div>
  );
}