import t from '@jetshop/intl';
import theme from '@jetshop/ui/utils/theme';
import React from 'react';
import styled, { css } from 'react-emotion';

import { FlyoutTarget, FlyoutTrigger } from '@jetshop/ui/Modal/Flyout';
import {
  Name,
  ProductBlockLink,
  ProductWrapper,
} from '../../../CategoryPage/GridProduct';
import { Price } from '../../../Price';
import UISuggestedTerm from './SuggestedTerm';

const flyout = css`
  position: absolute;
  top: 100%;
  background: #f8f8f8;
  padding: 40px 20px 20px 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.07);
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  max-width: 80rem;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  .see-all {
    align-items: center;
    padding: 0.5rem 1rem;
    color: white;
    cursor: pointer;
    text-align: center;
    height: 54px;
    font-family: 'Source Sans Pro', sans-serif;
    width: 100%;
    background: #00b55b;
    text-transform: uppercase;
    font-family: Abel, sans-serif;
    font-size: 1.3rem;
    margin-top: 10px;
  }
`;

const FlyoutInner = styled('div')`
  width: 100%;
  max-width: 1265px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  color: black;
  max-height: 55vh;
  overflow-y: scroll;
  ${Price.New} {
    color: ${theme('colors.red')};
  }
  ${Price.Old} {
    color: #adadad;
  }

  ${ProductWrapper} {
    padding: 10px;
    margin: 0;
  }
  ${ProductBlockLink} {
    margin: auto;
  }
  ${Name} {
    color: black;
  }
`;

const SuggestedTerm = styled(UISuggestedTerm)`
  list-style: none;
  margin-bottom: 1rem;
  width: 20%;
  height: 200px;
  border: 1px solid #000;
  :last-child {
    margin-bottom: 0;
  }
  a {
    color: ${({ theme }) => theme.colors.black};
    display: block;
    :hover {
      opacity: 0.7;
    }
  }
`;

const SearchFlyout = (searchProps) => {
  return (
    <FlyoutTrigger id="searchFlyout" showCover={true}>
      {({ hideTarget }) => {
        return (
          <FlyoutTarget id="searchFlyout">
            {(flyoutProps) =>
              searchProps.isOpen &&
              flyoutProps.isOpen &&
              searchProps.isDirty && (
                <div
                  className={`search-flyout ${flyout}`}
                  {...searchProps.getFlyoutProps()}
                >
                  <FlyoutInner>
                    {searchProps.loading
                      ? t('Loading…')
                      : searchProps.result.length > 0
                      ? searchProps.result.slice(0, 5).map((prod) => {
                          return (
                            <SuggestedTerm
                              key={prod.id}
                              product={prod}
                              onClick={(e) => {
                                searchProps.closeSearch();
                                hideTarget();
                              }}
                            />
                          );
                        })
                      : t('No matching products found')}
                  </FlyoutInner>
                  <button
                    className="see-all"
                    onClick={() => {
                      searchProps
                        .getFlyoutProps()
                        .triggerSearch(searchProps.term);
                      hideTarget();
                    }}
                  >
                    Visa alla sökresultat
                  </button>
                </div>
              )
            }
          </FlyoutTarget>
        );
      }}
    </FlyoutTrigger>
  );
};

export default SearchFlyout;
