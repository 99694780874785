import React from 'react';
import styled from 'react-emotion';
import { FlyoutTrigger } from '@jetshop/ui/Modal/Flyout';
import theme from '@jetshop/ui/utils/theme';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-light-svg-icons';

library.add(faSearch);

const SearchInputWrapper = styled('div')`
  display: flex;
  justify-self: center;
  flex: 1;
  z-index: 2;

  [data-channel='Eyewearstore'] & {
    bottom: auto;
  }
`;

const SearchInputWrapperInner = styled('div')`
  max-width: 320px;
  position: relative;

  input {
    width: 100%;
    background: white;
    border: 0;
    height: 40px;
    padding-left: 2.3rem;
    font-size: 16px;
    border: 1px solid ${theme('colors.grey')};
    &:focus {
      outline: none;
      + ${SearchIcon} {
        display: none;
      }
    }
  }
`;

const Input = styled.input``;

export const SearchIcon = styled(FontAwesomeIcon)`
  color: #5d5d5d;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.5rem;
`;

const SearchInput = ({ isDirty, getInputProps }) => {
  return (
    <FlyoutTrigger id="searchFlyout" showCover={false}>
      {({ showTarget, hideTarget, isOpen }) => {
        return (
          <SearchInputWrapper className="search-input-wrap">
            <SearchInputWrapperInner>
              <SearchIcon icon={['fal', 'search']} />
              <Input
                type="search"
                placeholder="Sök i butiken här"
                {...getInputProps({
                  onFocus: showTarget,
                  refKey: 'innerRef',
                })}
                isDirty={isDirty}
              />
            </SearchInputWrapperInner>
          </SearchInputWrapper>
        );
      }}
    </FlyoutTrigger>
  );
};

export default SearchInput;
