import { useContext } from 'react';
import ChannelContext from '@jetshop/core/components/ChannelContext/ChannelContext';

export function useChannel() {
  const { selectedChannel } = useContext(ChannelContext);

  return {
    isWeAhl: selectedChannel.id === 4,
    url: selectedChannel.url,
  };
}
