import React, { Fragment, Component } from 'react';
import { Redirect } from 'react-router';
import styled from 'react-emotion';

import { Query } from 'react-apollo';

import Drawer, { DrawerTarget } from '@jetshop/ui/Modal/Drawer';

import { FlyoutTarget } from '@jetshop/ui/Modal/Flyout';
import theme from '@jetshop/ui/utils/theme';

import t from '@jetshop/intl';

//import MultiList from './MultiList';
import MultiListFlyouts from '@jetshop/ui/Filter/FilterTypes/Multi/MultiListFlyouts';

import { ModelSelectorConsumer } from './ModelSelectorProvider';
import FilterContext from '@jetshop/core/components/Filters/FilterContext';
import HeaderBg from '../../img/header-background.jpg';

import { SelectDropdown as BaseDropdown } from '@jetshop/ui/Select';

import GET_CATEGORY from './Category.gql';

import { ClearButton, FilterToggler } from '../CategoryPage/Filters/Filter';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { Link } from 'react-router-dom';
import { Below, Above } from '@jetshop/ui/Breakpoints';
library.add(faTimes);

const Flyout = styled('div')`
  background: white;
  color: ${theme('colors.black')};
  ${({ theme }) => theme.above.md} {
    position: absolute;
    z-index: 999;
    right: 10%;
    top: 100%;
    width: 22rem;
    min-width: 60rem;
    max-width: 100%;
    padding: 1rem;
  }
`;

const CloseIcon = styled(FontAwesomeIcon)`
  font-size: 1.5rem;
  color: black;
  margin-right: 10px;
`;

const Header = styled('header')`
  position: relative;
  text-align: left;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 0 1rem 0;
  margin: 0 0 1rem 0;
  ${theme('below.md')} {
    padding: 0;
  }
  h2 {
    font-size: 2rem;
    text-transform: uppercase;
    ${theme('below.md')} {
      font-size: 1.5rem;
    }
  }
`;

const FakeSelect = styled('div')`
  height: 54px;
  border: 1px solid #dedede;
  margin-bottom: 16px;
  color: #c2c2c2;
  line-height: 52px;
  padding-left: 16px;
  background: white;
`;

const OptionsWrapper = styled('div')`
  width: 100%;
  padding: 1rem 10px;
  background-image: url(${HeaderBg});
  background-repeat: no-repeat;
  background-size: 200%;
  background-position: -120px -210px;
  ${theme('below.md')} {
    background-size: cover;
    background-position: center;
    padding-bottom: 5rem;
  }
  .flex {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    ${theme('below.md')} {
      flex-direction: column;
    }
    > div {
      flex: 1;
      margin: 10px;

      ${theme('below.md')} {
        margin: 10px 0;
        max-height: 180px !important;
        > div {
          max-height: 180px;
          > div {
            max-height: 180px;
          }
        }
      }
    }
  }
`;

const ModelFlyoutTitle = styled('span')`
  display: block;
  text-transform: uppercase;
  font-size: 1.8rem;
  color: white;
  margin-bottom: 5px;
  margin-left: 10px;
  ${theme('below.md')} {
    font-size: 1.2rem;
    margin-bottom: 0;
  }
`;

const StyledDrawer = styled(Drawer)`
  opacity: 0.5;
`;

const SelectedModel = styled('div')`
  margin-bottom: 0.5rem;
  ${theme('below.md')} {
    padding: 10px;
  }
  a {
    text-align: right;
    right: 20px;
    position: absolute;
    text-transform: uppercase;
    ${theme('below.md')} {
      position: relative;
      right: 0;
      display: block;
      text-align: left;
      margin-top: 1em;
      font-weight: 900;
    }
  }
`;

const MenuTitle = styled('div')`
  font-weight: bold;
  font-size: 16px;
  margin: 16px;

  ${theme('below.md')} {
    text-align: right;
    margin: 0;
    padding: 10px;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: normal;
    text-transform: uppercase;

    svg {
      font-size: 2rem;
      margin-left: 10px;
    }
  }
`;

const CloseModal = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
`;
const EmptyButtonIcon = styled(FontAwesomeIcon)`
  font-size: 2rem;
  margin-right: 10px;
  color: #a5a5a5;
`;

class CategorySelector extends Component {
  render() {
    const { selectCategory, modelCategory } = this.props;
    const availableCats = [
      { value: 106, text: 'Snöskoter' },
      { value: 201, text: 'Cross' },
      { value: 3444, text: 'ATV' },
      { value: 454, text: 'Motorcykel' }
    ];
    let selectedCat = {};
    for (var i = 0; i < availableCats.length; i++) {
      if (availableCats[i].value === parseInt(modelCategory)) {
        selectedCat = availableCats[i];
      }
    }
    return (
      <FilterContext.Consumer>
        {({ clearFilters }) => (
          <BaseDropdown
            items={availableCats}
            title={selectedCat ? selectedCat.text : 'Select Category'}
            onChange={e => {
              if (!e) {
                return;
              }
              if (e.value !== modelCategory) {
                clearFilters();
              }
              selectCategory(e.value);
            }}
            defaultSelectedItem={selectedCat}
          />
        )}
      </FilterContext.Consumer>
    );
  }
}

export class FilterQuery extends Component {
  state = {
    shouldRedirect: false
  };
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.activeFilter !== this.props.activeFilter &&
      this.props.activeFilter.multiListFilters.length > 0 &&
      this.props.activeFilter.multiListFilters[0].values.length === 3
    ) {
      this.props.turnOnFilter();
      if (prevProps.activeFilter.multiListFilters.length > 0) {
        if (
          this.props.activeFilter.multiListFilters[0].values[2] !==
          prevProps.activeFilter.multiListFilters[0].values[2]
        ) {
          this.setState({ shouldRedirect: true });
        }
      } else {
        this.setState({ shouldRedirect: true });
      }
    }
  }

  sortYears(a, b) {
    if (parseFloat(a.value) > parseFloat(b.value)) {
      return -1;
    } else {
      return 1;
    }
    return -1;
  }

  sortFilters(filters) {
    var multiListIndex = filters.findIndex(
      f => f.__typename === 'MultiListFilter'
    );
    var multiList = filters.filter(f => f.__typename === 'MultiListFilter');

    if (multiList.length > 0) {
      var yearIndex = multiList[0].lists.findIndex(f => f.name === 'Årsmodell');
      var year = multiList[0].lists.filter(f => f.name === 'Årsmodell');
      if (yearIndex > -1) {
        var yearItems = [];
        for (var i = 0; i < year[0].items.length; i++) {
          yearItems.push({
            value: year[0].items[i].value,
            text: year[0].items[i].text,
            resultCount: year[0].items[i].resultCount,
            __typename: year[0].items[i].__typename
          });
        }
        var yearObject = {
          name: year[0].name,
          items: yearItems.sort(this.sortYears),
          typename: year[0].__typename
        };

        var multiListObject = {
          id: multiList[0].id,
          lists: [multiList[0].lists[0], yearObject, multiList[0].lists[2]],
          name: multiList[0].name,
          __typename: multiList[0].__typename
        };
      }
      var filtersObject = [];
      if (multiListObject) {
        for (var i = 0; i < filters.length; i++) {
          if (i !== multiListIndex) {
            filtersObject.push(filters[i]);
          } else {
            filtersObject.push(multiListObject);
          }
        }
      } else {
        filtersObject = filters;
      }
    }

    return filtersObject;
  }

  render() {
    const {
      modelCategory,
      activeFilter,
      applyFilter,
      selectCategory,
      redirectUrl,
      removeRedirect,
      hideTarget,
      clearFilters,
      applyAllFilters,
      toggleFilter
    } = this.props;

    return (
      <React.Fragment>
        <OptionsWrapper>
          <ModelFlyoutTitle>Välj modell</ModelFlyoutTitle>
          <div className="flex">
            <CategorySelector
              selectCategory={selectCategory}
              modelCategory={modelCategory}
            />

            <Query
              query={GET_CATEGORY}
              variables={{ modelCategory, activeFilter }}
              fetchPolicy="network-only"
            >
              {({ loading, error, data }) => {
                if (loading) {
                  return (
                    <React.Fragment>
                      <FakeSelect>Märke</FakeSelect>
                      <FakeSelect>Årsmodell</FakeSelect>
                      <FakeSelect>Modell</FakeSelect>
                    </React.Fragment>
                  );
                }
                if (error) return error;
                var filters = this.sortFilters(data.category.products.filters);

                console.log(redirectUrl)
                
                return (
                  <React.Fragment>
                    <MultiListFlyouts
                      filters={filters}
                      result={data.category.products.result}
                      activeFilter={{ multiListFilters: [activeFilter] }}
                      applyFilter={applyFilter}
                    />
                    <Below breakpoint="md">
                      <FilterToggler
                        clearFilters={clearFilters}
                        activeFilters={activeFilter}
                        applyFilters={applyAllFilters}
                        flyout={true}
                        toggleFilter={toggleFilter}
                        filters={data.category.products.filters}
                      />
                    </Below>
                    <ClearButton
                      onClick={() => {
                        clearFilters();
                        if (
                          typeof window !== 'undefined' &&
                          typeof window.localStorage !== 'undefined'
                        ) {
                          localStorage.removeItem('savedFilter');
                        }
                      }}
                      className="clear-filters"
                    >
                      <FontAwesomeIcon icon={['fal', 'times']} />
                    </ClearButton>

                    {activeFilter.multiListFilters.length > 0 &&
                      activeFilter.multiListFilters[0].values.length === 3 &&
                      this.state.shouldRedirect &&
                      shouldRedirect(
                        redirectUrl,
                        removeRedirect,
                        hideTarget
                      ) && <Redirect to={redirectUrl} />}
                  </React.Fragment>
                );
              }}
            </Query>
          </div>
        </OptionsWrapper>
      </React.Fragment>
    );
  }
}

function shouldRedirect(redirectUrl, removeRedirect, hide) {
  hide();
  if (
    typeof window !== 'undefined' &&
    window.location.href.indexOf(redirectUrl) < 0
  ) {
    if (redirectUrl) {
      removeRedirect();
    }

    return true;
  }
}

const ModelFlyoutView = ({ modal, ...rest }) => {
  return (
    <FilterContext.Consumer>
      {({
        applyMultiFilter,
        activeFilters,
        onFilterChange,
        clearFilters,
        applyFilters,
        applyListFilter
      }) => (
        <ModelSelectorConsumer>
          {({
            selectCategory,
            modelCategory,
            redirectUrl,
            removeRedirect,
            toggleFilter,
            turnOnFilter
          }) => (
            <Flyout {...rest}>
              <Header url={redirectUrl}>
                <Above breakpoint="md">
                  <>
                  <h2>Välj din maskin</h2>
                  <CloseModal onClick={modal.hideTarget}>
                    <EmptyButtonIcon icon={['fal', 'times']} />{' '}
                  </CloseModal>
                  </>
                </Above>
              </Header>
              {activeFilters.multiListFilters &&
                activeFilters.multiListFilters.length > 0 &&
                activeFilters.multiListFilters[0].values.length === 3 && (
                  <SelectedModel>
                    {activeFilters.multiListFilters[0].values[0]}{' '}
                    {activeFilters.multiListFilters[0].values[1]}{' '}
                    {activeFilters.multiListFilters[0].values[2]}
                    <Link
                      onClick={() => {
                        applyListFilter('categories', []);
                        modal.hideTarget();
                      }}
                      to={redirectUrl ? redirectUrl : '/'}
                    >
                      Visa alla delar
                    </Link>
                  </SelectedModel>
                )}

              {modal.isOpen && (
                <FilterQuery
                  onFilterChange={onFilterChange}
                  activeFilter={activeFilters}
                  applyFilter={applyMultiFilter}
                  modelCategory={modelCategory}
                  selectCategory={selectCategory}
                  redirectUrl={redirectUrl}
                  removeRedirect={removeRedirect}
                  hideTarget={modal.hideTarget}
                  clearFilters={clearFilters}
                  applyAllFilters={applyFilters}
                  toggleFilter={toggleFilter}
                  turnOnFilter={turnOnFilter}
                />
              )}
            </Flyout>
          )}
        </ModelSelectorConsumer>
      )}
    </FilterContext.Consumer>
  );
};

const ModelFlyout = props => (
  // Wrap the flyout with the needed providers
  <Fragment>
    <FlyoutTarget id="model-flyout">
      {flyout => <ModelFlyoutView modal={flyout} {...props} />}
    </FlyoutTarget>

    <DrawerTarget id="model-drawer">
      {drawer => (
        <StyledDrawer isOpen={drawer.isOpen} right>
          <MenuTitle onClick={drawer.hideTarget}>
            <CloseIcon icon={['fal', 'times']} /> {t('Close')}
          </MenuTitle>
          <ModelFlyoutView modal={drawer} {...props} />
        </StyledDrawer>
      )}
    </DrawerTarget>
  </Fragment>
);

export default ModelFlyout;
