import styled from 'react-emotion';
export default styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  margin: 0 auto;
  padding: 0 1rem;
  position: relative;
  flex: 1 1 auto;
  width: 100%;
  max-width: 80rem;
`;
