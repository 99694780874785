import React, { Component } from 'react';
import styled from 'react-emotion';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
  faAngleLeft,
  faAngleRight
} from '@fortawesome/pro-light-svg-icons';
library.add(faTimes, faAngleLeft, faAngleRight);

const LightboxWrapper = styled('div')`
  iframe {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 1007;
    height: 70vh;
    width: 70vw;
  }
  img {
    z-index: 1006;
    position: fixed;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    top: 50%;
    max-width: 100%;
    max-height: 100%;
  }
`;

const LightboxOverlay = styled('div')`
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  z-index: 1005;
  left: 0;
  top: 0;
`;

const Close = styled('div')`
  position: fixed;
  color: white;
  z-index: 2000;
  font-size: 1.5rem;
  top: 20px;
  right: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #ececec;
`;

const CloseIcon = styled(FontAwesomeIcon)`
  margin-left: 10px;
  font-size: 2.5rem;
`;

const Arrow = styled(FontAwesomeIcon)`
  display: block;
  color: #ececec;
  font-size: 6rem;
  z-index: 1008;
  position: fixed;
  left: 5vw;
  top: 50%;
  margin-left: 10px;
  cursor: pointer;
  &.right {
    left: auto;
    right: 5vw;
    margin-right: 10px;
  }
`;

export default class LightBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      isOpen: true,
      activeIndex: 0
    };
  }
  componentDidMount() {
    document.addEventListener('keydown', this.onKeyPressed.bind(this));
    this.setState({
      isOpen: this.props.isOpen,
      index: false
    });
    this.setState({ index: this.props.activeIndex });
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyPressed.bind(this));
  }

  onKeyPressed = e => {
    if (e.keyCode === 37) {
      if (this.state.index - 1 < 0) {
        this.setState({ index: this.props.images.length - 1 });
      } else {
        this.setState({ index: this.state.index - 1 });
      }
    }
    if (e.keyCode === 39) {
      if (this.state.index + 1 > this.props.images.length - 1) {
        this.setState({ index: 0 });
      } else {
        this.setState({ index: this.state.index + 1 });
      }
    }
  };

  render() {
    const { images, lightboxVideo } = this.props;
    const fullImage = images[this.state.index];

    return (
      <LightboxWrapper tabIndex="0" onKeyDown={this.onKeyPressed}>
        {this.state.isOpen && (
          <React.Fragment>
            <LightboxOverlay onClick={() => this.props.closeLightbox()} />
            <Close onClick={() => this.props.closeLightbox()}>
              Stäng <CloseIcon icon={['fal', 'times']} />
            </Close>
            {(lightboxVideo && (
              <iframe
                title="Lightbox Video"
                width="560"
                height="315"
                src={lightboxVideo}
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            )) || (
              <React.Fragment>
                <Arrow
                  icon={['fal', 'angle-left']}
                  onClick={() => {
                    if (this.state.index - 1 < 0) {
                      this.setState({ index: images.length - 1 });
                    } else {
                      this.setState({ index: this.state.index - 1 });
                    }
                  }}
                >
                  {' '}
                  &lt;{' '}
                </Arrow>
                <Arrow
                  icon={['fal', 'angle-right']}
                  onClick={() => {
                    if (this.state.index + 1 > images.length - 1) {
                      this.setState({ index: 0 });
                    } else {
                      this.setState({ index: this.state.index + 1 });
                    }
                  }}
                  className="right"
                >
                  {' '}
                  &gt;{' '}
                </Arrow>
                {fullImage && <img src={fullImage.url} alt={fullImage.alt} />}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </LightboxWrapper>
    );
  }
}
