import React, { Component } from 'react';
import Slider from 'react-slick';
import styled from 'react-emotion';

import theme from '@jetshop/ui/utils/theme';

import Image from '@jetshop/ui/Image';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/pro-light-svg-icons';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
library.add(faPlay, faAngleDown, faAngleUp);

const ProductImage = styled(Image)`
  background-size: auto;
  background-position: center;
  
  background-color: white;
  ${theme('below.md')} {
  
  }
`;

const StyledSlider = styled(Slider)`
  width: 100%;
  .slick-list {
    overflow: hidden;
    height: 569px !important;
  }
  .slick-track {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    ${theme('below.md')} {
      flex-direction: row;
      flex-wrap: wrap;
      margin-left: -5px;
      margin-right: -5px;
      margin-top: 5px;
    }
    .slick-slide {
      border: 3px solid #f8f8f8;
      border-right: 0;
      width: 5rem!important;
      height: 5rem!important;
      img {
        object-fit: cover!important;
      }
    }
    .slick-slide > div > div {
      display: block;
      width: 100%;
      margin: 0.667rem;
      margin-top: 0;
      height: 5rem;
      width: 5rem !important;
      background-size: contain;
      background-position: center;
      cursor: pointer;
      text-align: center;
      position: relative;
      color: #fff;
      background-color: white;
      svg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        margin: auto;
        font-size: 2rem;
      }
      :hover {
        opacity: 0.9;
      }
      ${theme('below.md')} {
        width: 20%;
        margin: 5px;
        flex-grow: 1;
        background-size: contain;
      }
    }
  }

  .slick-arrow {
    height: 27.5px;
    width: 5rem;
    border-left: 3px solid #f8f8f8;
    margin-top: 0;
    text-align: center;
    font-size: 2rem;
    line-height: 27.5px;
    background: #bcbabb;
    color: white;
    position: relative;
    svg {
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      margin: auto;
    }

    &.slick-prev {
    }
    &.slick-next {
    }
  }

  .video-li {
    position: relative;
    &:before {
      content: '';
      background: rgba(255,255,255,0.7);
      position: absolute;
      top: 0;
      right: 0;
      bottom: -3px;
      left: 0;
      z-index: 1;
    }
    &:after {
      content: '';
      height: 35px;
      width: 35px;
      border-radius: 100%;
      border: 2px solid black;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      margin: auto;
    }
    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      right: 0;
      margin: auto;
      z-index: 1;
    }
  }
`;

export default class VerticalSwipeToSlide extends Component {
  render() {
    const images = this.props.images;
    const video = this.props.video;
    const videoLightbox = this.props.videoLightbox;
    const selectImage = this.props.selectImage;

    function SampleNextArrow(props) {
      const { className, onClick } = props;
      return (
        <div className={className} onClick={onClick}>
          <FontAwesomeIcon icon={['fal', 'angle-down']} />
        </div>
      );
    }

    function SamplePrevArrow(props) {
      const { className, onClick } = props;
      return (
        <div className={className} onClick={onClick}>
          <FontAwesomeIcon icon={['fal', 'angle-up']} />
        </div>
      );
    }

    const settings = {
      dots: false,
      infinite: true,
      slidesToShow: 6,
      slidesToScroll: 1,
      vertical: true,
      verticalSwiping: true,
      swipeToSlide: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />
    };
    return (
      <StyledSlider {...settings}>
        {images.map((img, i) => (
          <li
            key={i}
            onClick={() => selectImage(i)}
            css={{
              background: `url(${img.sizes &&
                img.sizes[1] &&
                img.sizes[1].url +
                  '?method=thumbnail&width=300&height=auto'}) center center no-repeat`
            }}
          >
            <ProductImage
              onClick={() => selectImage(i)}
              aspect="1:1"
              src={img.url}
            />
          </li>
        ))}
        {video && (
          <li
            key={
              images[0].url
            }
            onClick={() => videoLightbox(video)}
            className="video-li"
          >
            <FontAwesomeIcon icon={['fa', 'play']} />
            <ProductImage
              aspect="1:1"
              src={images[0].url}
            />
          </li>
        )}
      </StyledSlider>
    );
  }
}
