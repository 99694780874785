import React, { useState, useEffect, useRef } from 'react';
import styled from 'react-emotion';

import theme from '@jetshop/ui/utils/theme';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import { useInView } from 'react-intersection-observer';
import { useChannel } from '../../hooks/useChannel';

library.add(faStar);

export const RatingWrapper = styled('div')`
  display: flex;
  align-items: center;
  margin-top: 0.5em;
  cursor: pointer;
  .rating-dummy {
    height: 15px;
  }
  &.product-page {
    margin-bottom: 1rem;
    justify-content: center;
  }
  div {
    display: flex;
  }
  span {
    display: inline-flex;
    font-size: 10px;
    color: #b7b7b7;
    margin-left: 2px;
    padding-top: 2px;
    &.black {
      color: ${theme('colors.black')};
    }
  }
  ${theme('below.lg')} {
    &.not-product-page {
      svg {
        font-size: 0.55rem;
        margin: 0 1px;
      }
    }
  }
`;

export const RatingStar = styled(FontAwesomeIcon)`
  font-size: 0.8rem;
  margin: 0 2px;
  color: #fed227;

  &.grey {
    color: #ddd;
  }
`;

function ProductRatings({ product, productPage, handleClick }) {
  const [data, setData] = useState(null);
  const [ref, inView] = useInView({ triggerOnce: true, rootMargin: '200px' });
  const { yotpoKey } = useShopConfig();
  const mounted = useRef(false);
  const { isWeAhl } = useChannel();

  useEffect(() => {
    mounted.current = true;

    async function fetchReviews() {
      const reviews = await fetch(
        `https://api.yotpo.com/v1/widget/${yotpoKey}/products/${product.articleNumber}/reviews.json`
      );
      setData(await reviews.json());
    }

    if (mounted && inView && !isWeAhl) {
      fetchReviews();
    }

    return () => (mounted.current = false);
  }, [inView, mounted]);

  return (
    <RatingWrapper
      onClick={() => handleClick()}
      className={productPage ? 'product-page' : 'not-product-page'}
    >
      <span ref={ref}>
        {data ? (
          <Stars response={data} product={product} />
        ) : (
          <div className="rating-dummy" />
        )}
      </span>
    </RatingWrapper>
  );
}

function Stars({ response }) {
  if (!response) {
    return null;
  }
  const average_score = response?.response?.bottomline?.average_score || 0;

  if (average_score === 0) {
    return null;
  }

  const rounded = Math.round(average_score);
  const score = Array(rounded)
    .fill(true)
    .concat(Array(5 - rounded).fill(false));

  return score.map((approved, i) => (
    <RatingStar
      className={approved ? 'gold' : 'grey'}
      key={i}
      icon={['fa', 'star']}
    />
  ));
}

export default ProductRatings;
