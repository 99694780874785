import { baseTheme } from '@jetshop/ui/BaseTheme';

import { createBreakpointHelpers } from '@jetshop/ui/utils/breakpoints';
export const labels = ['xs', 'sm', 'md', 'lg'];
const breakpoints = ['20rem', '40rem', '74rem', '75rem'];
const { breakpointMap, above, below, between, only } = createBreakpointHelpers(
  labels,
  breakpoints
);

const colors = {
  ...baseTheme.colors,
  red: '#cd3132',
  green: '#00b55b',
  font: '#212121',
  buy: '#00b55b',
  dark: '#000',
  darkGrey: '#212121',
  light: '#fff',
  discountedPrice: '#e01527',
  lightGrey: '#646464',
  eyeWearYellow: '#f9b233',
};

const general = {
  maxWidth: '80em',
};

export const SkoterdelenTheme = {
  ...baseTheme,
  general,
  colors,
  labels,
  breakpoints,
  breakpointMap,
  above,
  below,
  between,
  only,
  font: {
    primary: 'Abel,sans-serif',
  },
};
